<script>
  import IconButton from "@smui/icon-button";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import additionalDataForDeliverylist from "~/libs/additionalDataForDeliverylist";
  import addressUtils from "~/libs/addressUtils";
  import { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    DeliveryRecordTypes,
    LastOperationTypes,
    NotificationCategory,
  } from "~/libs/constants";
  import { IndexedDbConnectErrorException } from "~/libs/db";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import geolocator from "~/libs/geolocator";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import requestsQueueForRetry from "~/libs/requestsQueueForRetry";
  import { lastOperationType } from "~/libs/stores";
  import {
    reserveUpdateDeliveryRecordsAndSyncBackend,
    updateDeliveryRecordsByPastDelivery,
  } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Function} 配達リストの再読み込み関数 */
  export let reloadShippingList;

  /** @type {boolean} 送信失敗リストの有無 */
  export let hasSyncFailurePackages;

  /** @type {ConfirmDialog} 送信待ちリストダイアログコンポーネントのインスタンス */
  let syncFailureListDialog;

  /** @type {Array<import("~/libs/commonTypes").SyncFailureDeliveryPackage>} */
  let syncFailureList = userContext.syncFailureList;

  /** @type {number} ダイアログ表示時点のタイムスタンプ */
  let requestedTimeStamp;

  /** @type {ConfirmDialog} 送信キャンセル確認ダイアログコンポーネントのインスタンス */
  let confirmCancelDialog;

  /** @type {number} 送信キャンセル対象荷物の送信待ちリストIndex */
  let cancelPackageIndex;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    syncFailureListDialog.openDialog();

    // 配達実績同期のため位置情報を取得しておく
    requestedTimeStamp = Date.now();
    geolocator.requestCurrentPosition(false, requestedTimeStamp);
  }

  /**
   * リクエストの再送を行い、配達リスト画面に反映する
   * @param {string} id
   */
  async function resendAndUpdateList(id) {
    try {
      // idを指定して送信待ちリクエストを再送
      const result = await requestsQueueForRetry.resendById(
        id,
        userContext.loginUser?.username,
      );

      // 配達実績を送信する
      await updateDeliveryRecordsByPastDelivery(
        userContext,
        result.trackingNumber,
        result.updateRequestJson,
        requestedTimeStamp,
      );

      // 該当の荷物を更新失敗リストから配達リストに戻す
      await updateLocalStorage(
        result.trackingNumber,
        result.updateResponse,
        result.updateRequestJson,
      );

      // DBから該当のリクエストを削除
      await requestsQueueForRetry.deleteRequestsById(
        id,
        userContext.loginUser?.username,
      );

      // 配達リストの再読み込み
      reloadShippingList(userContext.deliveryList);

      if (syncFailureList.length === 0) {
        // 送信待ちリストが空になった場合、ダイアログを閉じる
        hasSyncFailurePackages = false;
        syncFailureListDialog.closeDialog();
        if (
          deliveryListUtils.canFinishWork(
            userContext.deliveryList,
            userContext.syncFailureList,
          )
        ) {
          // 配達リストの持ち出し中荷物も無い場合、
          // 業務を終了しても問題無いように稼働状況の同期を即座に行う
          reserveUpdateDeliveryRecordsAndSyncBackend(
            userContext,
            requestedTimeStamp,
            DeliveryRecordTypes.ALL_DELIVERED,
          );

          // 業務終了のダイアログを表示する
          lastOperationType.set(LastOperationTypes.ALL_DELIVERED);
        }
      }
    } catch (error) {
      if (error instanceof IndexedDbConnectErrorException) {
        toast.error($_("errors.failedGetRequestsQueue"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 401
      ) {
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      }
      // 権限エラー等の場合
      else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 403
      ) {
        toast.error($_("errors.forbidden"));
      }
      // その他サーバーエラー応答を受信した場合
      else {
        console.error(error);
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * ローカルストレージの更新
   * @param {string} trackingNumber
   * @param {import("~/libs/backendApi").UpdateShipmentStatusResponse} updateResponse
   * @param {object} updateRequestJson
   */
  function updateLocalStorage(
    trackingNumber,
    updateResponse,
    updateRequestJson,
  ) {
    const deliveryList = userContext.deliveryList ?? [];
    const tmpSyncFailureList = userContext.syncFailureList ?? [];
    const index = tmpSyncFailureList.findIndex(
      (e) => e.trackingNumber === trackingNumber,
    );

    if (updateResponse.updateFailed) {
      // 更新結果がupdateFailedだった場合、メッセージを表示
      toast.error(
        $_("errors.isCannotBeDeliveredPackage_2", {
          values: {
            trackingNumber: formatTrackingNumber(trackingNumber),
          },
        }),
      );
    } else {
      // 更新結果が成功だった場合
      // 更新失敗リストから配達リストにコピー
      deliveryList.push(deleteSyncFailureInfo(tmpSyncFailureList[index]));

      // 更新情報を反映
      deliveryListUtils.updateDeliveryList(
        deliveryList,
        updateResponse.success[0],
        updateRequestJson,
      );

      toast.info($_("message.resendSuccessful"));
    }
    // 更新失敗リストから削除
    tmpSyncFailureList.splice(index, 1);

    userContext.deliveryList = deliveryList;
    userContext.syncFailureList = tmpSyncFailureList;
    syncFailureList = tmpSyncFailureList;
    userContext.store();
  }

  /**
   * 送信失敗リストから削除
   * @param {string} trackingNumber
   */
  function deleteFromSyncFailureList(trackingNumber) {
    // リストから削除する荷物の付加データも削除
    additionalDataForDeliverylist
      .deleteByTrackingNumber(trackingNumber, userContext.loginUser?.username)
      .catch((error) => {
        // ユーザーによる能動的な削除ではないため、エラートーストは出さずに処理を継続する
        console.log(error);
      });

    const tmpSyncFailureList = userContext.syncFailureList ?? [];
    const index = tmpSyncFailureList.findIndex(
      (e) => e.trackingNumber === trackingNumber,
    );

    // 削除する荷物の情報をお知らせに残す
    notificationHistoryUtils
      .deleteAndAddHistory(
        userContext.loginUser.username,
        NotificationCategory.INFO,
        $_("message.cantResend", {
          values: {
            trackingNumber: formatTrackingNumber(trackingNumber),
            address: `${addressUtils
              .trimPrefecture(tmpSyncFailureList[index].receiverAddress1)
              .substring(0, 6)}...`,
            name: tmpSyncFailureList[index].receiverName.split(/\s+/)[0],
          },
        }),
      )
      .catch((error) => {
        // インフォトーストで「お知らせ機能から確認できる」旨を記載している、かつリストから情報が消えてしまうのでお知らせに登録できなかった旨をエラートーストで表示する。
        console.log(error);
        toast.error($_("errors.failedRegistNotificationHistory"));
      });

    tmpSyncFailureList.splice(index, 1);
    userContext.syncFailureList = tmpSyncFailureList;
    syncFailureList = tmpSyncFailureList;
    userContext.store();

    toast.info($_("message.deleteFromSyncFailureListSuccessful"));

    if (syncFailureList.length === 0) {
      hasSyncFailurePackages = false;
      syncFailureListDialog.closeDialog();
    }
  }

  /**
   * 送信をキャンセルして送信待ちリストから配達リストに戻す
   */
  async function sendCancel() {
    // indexedDBから対象のリクエストを削除
    await requestsQueueForRetry.deleteRequestsById(
      syncFailureList[cancelPackageIndex].retryId,
      userContext.loginUser?.username,
    );

    // 荷物データを送信待ちリストから配達リストに戻す
    const deliveryList = userContext.deliveryList;
    deliveryList.push(
      deleteSyncFailureInfo(syncFailureList[cancelPackageIndex]),
    );
    syncFailureList.splice(cancelPackageIndex, 1);
    syncFailureList = syncFailureList;
    userContext.store();

    // 配達リストの再読み込み
    reloadShippingList(userContext.deliveryList);

    // キャンセル完了の旨メッセージ表示
    toast.info($_("message.cancelFromSyncFailureListSuccessful"));

    // 送信待ちリストが空になったらダイアログを閉じる
    if (syncFailureList.length === 0) {
      hasSyncFailurePackages = false;
      syncFailureListDialog.closeDialog();
    }
  }

  /**
   * 荷物データから送信待ちリスト用の項目を削除する
   *
   * @param {import("~/libs/commonTypes").SyncFailureDeliveryPackage} deliveryPackage
   * @returns {import("~/libs/commonTypes").DeliveryPackage}
   */
  function deleteSyncFailureInfo(deliveryPackage) {
    delete deliveryPackage.retryId;
    delete deliveryPackage.failedTime;
    delete deliveryPackage.reasonForUndeliverable;
    return deliveryPackage;
  }
</script>

<div class="syncFailureListDialog wideWidthMdcDialog">
  <ConfirmDialog
    bind:this={syncFailureListDialog}
    type={ConfirmDialogTypes.CLOSE}
    mandatory={false}
  >
    <svelte:fragment slot="title">送信待ち荷物リスト</svelte:fragment>
    <svelte:fragment slot="content">
      <div class="syncFailureListArea">
        {#each syncFailureList as item, index}
          {#if index > 0}
            <div class="divider" />
          {/if}
          <section>
            <div class="mainContents">
              <div class="packageInfoArea">
                <p class="line1">
                  {formatDate(new Date(item.failedTime), "M/d(E) HH:mm", {
                    locale: localeJa,
                  })}
                  <span class="">
                    {#if Number.isInteger(item.reasonForUndeliverable)}
                      {$_(
                        `pages.List.extraEventTypeLabel.${item.reasonForUndeliverable}`,
                      )}
                    {:else}
                      配達完了
                    {/if}
                  </span>
                </p>
                <p class="line2">{formatTrackingNumber(item.trackingNumber)}</p>
              </div>
              <div class="buttonArea">
                {#if Number.isInteger(item.reasonForUndeliverable)}
                  <IconButton
                    class="material-icons"
                    size="button"
                    style="font-size: 30px; color: var(--mdc-theme-error); margin-right: 10px;"
                    on:click={() => {
                      confirmCancelDialog.openDialog();
                      cancelPackageIndex = index;
                    }}>cancel</IconButton
                  >
                {/if}
                {#if item.retryId}
                  <IconButton
                    class="material-icons"
                    size="button"
                    style="font-size: 30px; color: var(--mdc-theme-secondary)"
                    on:click={() => {
                      resendAndUpdateList(item.retryId);
                    }}>send</IconButton
                  >
                {:else}
                  <IconButton
                    class="material-icons"
                    size="button"
                    style="font-size: 30px;"
                    on:click={() => {
                      deleteFromSyncFailureList(item.trackingNumber);
                    }}>delete_sweep</IconButton
                  >
                {/if}
              </div>
            </div>
            {#if !item.retryId}
              <div class="subContents">
                <p class="caution">{$_("errors.cantResend")}</p>
              </div>
            {/if}
          </section>
        {/each}
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>
<div class="confirmCancelDialog">
  <ConfirmDialog
    bind:this={confirmCancelDialog}
    type={ConfirmDialogTypes.OK_CANCEL_CLOSE}
    mandatory={true}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        sendCancel();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content">
      配達不可登録をキャンセルして、荷物を配達リストに戻します。
      よろしいですか？
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .syncFailureListDialog {
    :global(.mdc-dialog__title) {
      text-align: center;
    }

    .syncFailureListArea {
      max-height: 70vh;
      overflow-y: auto;
      border: solid 1px #e7e7e7;
      border-radius: 10px;

      .divider {
        height: 1px;
        background-color: #e7e7e7;
      }

      section {
        padding: 6px 10px;
        display: column;

        .mainContents {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .packageInfoArea {
            font-size: 15px;

            .line1 {
              line-height: 1;
            }

            .line2 {
              margin: 5px 0 0 5px;
              font-size: large;
              line-height: 1.2;
            }
          }

          .buttonArea {
            margin-left: auto;
            display: flex;
            align-items: center;
          }
        }

        .caution {
          font-size: 14px;
          margin-top: 5px;
          background-color: #ffe7e7;
          color: #c80000;
          border-radius: 4px;
          padding: 3px 4px;
          line-height: 1.4;
        }
      }
    }
  }
</style>
