import { writable } from "svelte/store";

/**
 * 配達中の荷物情報
 * @type {import("svelte/store").Writable<import("~/libs/commonTypes").DeliveryPackage>}
 */
export const deliveryTarget = writable();

/**
 * 荷受け／持出し／持ち戻り／返品作業を行うセンター情報
 * @type {import("svelte/store").Writable<string>}
 */
export const updateCenter = writable("");

/**
 * AppPageの画面遷移に使用するStore情報
 * @type {import("svelte/store").Writable<import("~/libs/commonTypes").AppPageStore>}
 */
export const appPageStore = writable();

/**
 * 現在地情報
 * @type {import("svelte/store").Writable<GeolocationPosition>}
 */
export const currentPositionStore = writable();

/**
 * オフラインモード切替えヘルプの表示状態
 * @type {import("svelte/store").Writable<boolean>}
 */
export const displayOfflineModeHelp = writable(false);

/**
 * 再ログインダイアログの表示状態
 * @type {import("svelte/store").Writable<boolean>}
 */
export const displayReLoginDialog = writable(false);

/**
 * 配達リストを再読み込みするかどうか
 * @type {import("svelte/store").Writable<boolean>}
 */
export const needToReload = writable(false);

/**
 * 配達ナレッジを取得するかどうか
 * @type {import("svelte/store").Writable<boolean>}
 */
export const needToGettingKnowledge = writable(false);

/**
 * 受信したプッシュ通知情報
 * @type {import("svelte/store").Writable<import("~/libs/commonTypes").PushNotificationInfo>}
 */
export const receivedPushInfo = writable();

/**
 * 開封されたプッシュ通知情報
 * @type {import("svelte/store").Writable<import("~/libs/commonTypes").PushNotificationInfo>}
 */
export const openedPushInfo = writable();

/**
 * 業務終了直前の操作種別
 * @type {import("svelte/store").Writable<import("~/libs/constants").LastOperationTypes>}
 */
export const lastOperationType = writable();
