<script>
  import { onMount, tick } from "svelte";

  export let helpContents;
  export let clickConfirm;
  export let clickCancel = null;
  export let showWhenToHandover;

  let close = true;

  onMount(async () => {
    await tick();
    close = false;
  });

  async function handleClickConfirm(event) {
    close = true;
    if (event.detail) {
      helpContents = event.detail;
      close = false;
    } else {
      setTimeout(clickConfirm, 500);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function handleClickCancel(event) {
    close = true;
    setTimeout(clickCancel, 500);
  }
</script>

<div class="filter" class:fadein={!close} class:fadeout={close}>
  <div class="paper">
    <svelte:component
      this={helpContents}
      {showWhenToHandover}
      on:confirm={handleClickConfirm}
      on:cancel={handleClickCancel}
    />
  </div>
</div>

<style>
  .filter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: var(--z-index-modal);
  }
  .fadein {
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .fadeout {
    animation-name: fadeout;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .paper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 90vw;
    max-width: 400px;
    min-width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255);
    border-radius: 20px;
    overflow: hidden;
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
