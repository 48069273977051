<script>
  import Button from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import PasswordStrengthChecker from "~/components/PasswordStrengthChecker.svelte";
  import backendApi from "~/libs/backendApi";
  import { PasswordStrength } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** ユーザーID @type {string} */
  export let userId;
  /** パスワード @type {string} */
  export let pw;
  /** パスワードリセットページを表示するか否か @type {boolean} */
  export let showsPasswordResetPage;

  /** メールアドレス */
  let emailAddress = "";

  /** 新しいパスワード */
  let newPw1 = "";
  /** 新しいパスワードのtype属性値 */
  let newPw1Type = "password";
  /** 新しいパスワード（再入力） */
  let newPw2 = "";
  /** 新しいパスワード（再入力）のtype属性値 */
  let newPw2Type = "password";
  /** PINコード */
  let pin = "";

  /** エラーメッセージ @type {string} */
  let errorMessage;

  /** 新しいパスワードの入力欄を表示するか否か */
  let showsNewPasswordInput = false;

  /** パスワード強度 @type {PasswordStrength} */
  let passwordStrength;

  /** パスワードリセットを要求した日時（UNIX epoch） */
  let resetRequestedTime = 0;

  /** 「PINコードを取得」ボタンのdisabled属性を有効にするか否か */
  $: getPinCodeButtonDisabled = !(userId && /^\S+@\S+$/.test(emailAddress));

  /** 「パスワードをリセット」ボタンのdisabled属性を有効にするか否か */
  $: resetPasswordButtonDisabled = !(
    newPw1 &&
    newPw2 &&
    /^[0-9]{6}$/.test(pin) &&
    passwordStrength === PasswordStrength.STRONG &&
    newPw1 === newPw2
  );

  const getPinCode = loadingProgress.wrapAsync(async () => {
    try {
      await backendApi.reqeustPasswordReset({
        userName: userId,
        emailAddress: emailAddress,
      });

      toast.info($_("message.requestPwReset"));
      errorMessage = null;

      resetRequestedTime = Date.now();
      showsNewPasswordInput = true;
    } catch (error) {
      showErrorMessage(error);
    }
  });

  const resetPassword = loadingProgress.wrapAsync(async () => {
    // PINコードの有効期限が切れている場合（パスワードリセット要求から10分以上経過）はやり直しさせる
    if (Date.now() - resetRequestedTime > 10 * 60 * 1000) {
      toast.error($_("errors.pwResetPinExpired"), {
        initial: 0,
        popsWhenPageMoved: true,
      });
      return;
    }

    try {
      await backendApi.passwordReset({
        userName: userId,
        pin: pin,
        newPassword: newPw1,
      });

      // iOSネイティブアプリの場合は認証情報の保存をサジェストさせる
      iosNativeApp.saveCredentials(userId, newPw1);

      toast.info($_("message.pwResetComplete"));
      errorMessage = null;

      pw = newPw1;
      showsPasswordResetPage = false;
    } catch (error) {
      showErrorMessage(error);
    }
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (
      error instanceof HTTPError &&
      error.response &&
      error.response.status == 400
    ) {
      errorMessage = $_("errors.pwResetFailed");
    } else {
      errorMessage = $_("errors.defaultMessage");
    }
  }
</script>

<p class="pageOverview">
  {#if !showsNewPasswordInput}
    ユーザーIDとメールアドレスを入力してPINコードを取得してください。
  {:else}
    <p>新しいパスワードとメールに記載のPINコードを入力してください。</p>
    <p>
      メールが5分以内に届かない場合はユーザーIDとメールアドレスに間違いがないか確認してください。
    </p>
  {/if}
</p>

{#if !showsNewPasswordInput}
  {@const onEnterKeyDownHandler = (/** @type {KeyboardEvent} */ event) => {
    if (event.key === "Enter" && !getPinCodeButtonDisabled) {
      getPinCode();
    }
  }}
  <div class="inputField">
    <Textfield
      type="text"
      label="ユーザーID"
      variant="outlined"
      style="margin-top: 30px;"
      required
      input$id="username"
      input$name="username"
      input$autocomplete="username"
      bind:value={userId}
      on:keydown={onEnterKeyDownHandler}
    />
  </div>
  <div class="inputField">
    <Textfield
      type="email"
      label="メールアドレス"
      variant="outlined"
      style="margin-top: 15px;"
      required
      input$id="email"
      input$name="email"
      input$autocomplete="email"
      input$inputmode="email"
      bind:value={emailAddress}
      on:keydown={onEnterKeyDownHandler}
    />
  </div>
{:else}
  {@const onEnterKeyDownHandler = (/** @type {KeyboardEvent} */ event) => {
    if (event.key === "Enter" && !resetPasswordButtonDisabled) {
      resetPassword();
    }
  }}
  <!-- iOS16以下で発生するパスワード自動生成時に2つの入力欄に別々のパスワードが生成される問題を回避するため、ダミーのformタグを利用 -->
  <form
    method="post"
    action="#"
    id="passwordResetForm"
    on:submit|preventDefault={() => {
      return false;
    }}
  >
    <div class="inputField">
      <Textfield
        type="text"
        label="ユーザーID"
        variant="outlined"
        style="margin-top: 30px;"
        input$id="username"
        input$name="username"
        input$autocomplete="username"
        input$readonly
        bind:value={userId}
      />
    </div>
    <div class="inputField passwordField">
      <Textfield
        type={newPw1Type}
        label="新しいパスワード"
        variant="outlined"
        style="margin-top: 15px;"
        required
        input$id="new-password"
        input$name="new-password"
        input$autocomplete="new-password"
        bind:value={newPw1}
        on:keydown={onEnterKeyDownHandler}
      >
        <IconButton
          type="button"
          slot="trailingIcon"
          class="material-icons md-dark"
          tabindex={-1}
          on:click={() => {
            newPw1Type = newPw1Type == "text" ? "password" : "text";
          }}
        >
          {newPw1Type == "text" ? "visibility_off" : "visibility"}
        </IconButton>
      </Textfield>
    </div>
    <div class="inputField passwordField">
      <Textfield
        type={newPw2Type}
        label="新しいパスワード（再入力）"
        variant="outlined"
        style="margin-top: 15px;"
        required
        input$id="new-password-2"
        input$name="new-password"
        input$autocomplete="new-password"
        bind:value={newPw2}
        on:keydown={onEnterKeyDownHandler}
      >
        <IconButton
          type="button"
          slot="trailingIcon"
          class="material-icons md-dark"
          tabindex={-1}
          on:click={() => {
            newPw2Type = newPw2Type == "text" ? "password" : "text";
          }}
        >
          {newPw2Type == "text" ? "visibility_off" : "visibility"}
        </IconButton>
      </Textfield>
    </div>
    <div class="inputField">
      <Textfield
        type="text"
        label="PINコード(6桁)"
        variant="outlined"
        style="margin-top: 15px;"
        input$id="one-time-code"
        input$name="one-time-code"
        input$autocomplete="off"
        input$minlength={6}
        input$maxlength={6}
        input$pattern="[0-9]{'{'}6{'}'}"
        input$inputmode="numeric"
        bind:value={pin}
        on:keydown={onEnterKeyDownHandler}
      ></Textfield>
    </div>
  </form>
  <div class="passwordStrengthChecker">
    <PasswordStrengthChecker password={newPw1} bind:passwordStrength />
  </div>
{/if}

{#if errorMessage}
  <p class="errorMessage">{errorMessage}</p>
{/if}

<div class="command">
  {#if !showsNewPasswordInput}
    <Button
      type="submit"
      variant="unelevated"
      style="width: 190px; height: 50px;"
      touch
      bind:disabled={getPinCodeButtonDisabled}
      on:click={getPinCode}
      >PINコードを取得
    </Button>
  {:else}
    <Button
      type="submit"
      variant="unelevated"
      style="width: 190px; height: 50px;"
      touch
      bind:disabled={resetPasswordButtonDisabled}
      on:click={resetPassword}
      >パスワードをリセット
    </Button>
  {/if}
  <Button
    type="submit"
    variant="unelevated"
    style="width: 190px; height: 50px; background-color: #828282;"
    touch
    on:click={() => {
      showsPasswordResetPage = false;
    }}
    >戻る
  </Button>
</div>

<style lang="scss">
  .pageOverview {
    p:nth-child(n + 2) {
      margin-top: 6px;
    }
  }
</style>
