<script>
  import Button, { Label } from "@smui/button";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import SegmentedButton, { Segment } from "@smui/segmented-button";
  import Switch from "@smui/switch";
  import { formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import sceneImageOutForDelivery from "~/assets/images/sceneOutForDelivery.png";
  import sceneImagePickupAndSort from "~/assets/images/scenePickupAndSort.png";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    ConfirmDialogTypes,
    DRIVER_ROLE,
    DriverType,
    QrHomeTypes,
  } from "~/libs/constants";
  import geolocator from "~/libs/geolocator";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { currentPositionStore } from "~/libs/stores";
  import { switchRole } from "~/libs/switchRole";
  import { toast } from "~/libs/toast";
  import workManage, { AlreadyBeenStartedException } from "~/libs/workManage";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let time = new Date();

  /** @type {ConfirmDialog} 位置情報取得不可時の確認ダイアログコンポーネントのインスタンス */
  let currentPositionUnavailableDialog;

  /** @type {ConfirmDialog} 複数端末利用に関する確認ダイアログコンポーネントのインスタンス */
  let multiDeviceDialog;

  /** @type {boolean} 複数端末利用するか否か */
  let isUseMultiDevice;

  /** @type {boolean} 読取専用モードを利用するか否か */
  let isReadOnlyMode = appContext.isReadOnlyMode ?? false;

  /** @type {boolean} 読取専用モードを利用するか否か(選択中状態) */
  let isReadOnlyModeTemp;

  /**
   * 切り替え可能なロールのリスト
   * 現時点では固定で宅配業務と幹線輸送業務のみ
   * @type {Array<DRIVER_ROLE | CORE_DELIVERY_ROLE>}
   */
  let switchableRoles = [DRIVER_ROLE, CORE_DELIVERY_ROLE];

  /** 選択中ロールのindex @type {DRIVER_ROLE | CORE_DELIVERY_ROLE} */
  let selectedRole =
    // ロール切替え可能かつ前回のロールが幹線輸送業務の場合は幹線輸送業務を選択状態にする
    userContext.canSwitchRole() &&
    appContext.previousRole === CORE_DELIVERY_ROLE
      ? CORE_DELIVERY_ROLE
      : // ロール切替え可能かつ前回のロールが宅配業務の場合は宅配業務を選択状態にする
        userContext.canSwitchRole() && appContext.previousRole === DRIVER_ROLE
        ? DRIVER_ROLE
        : // ロール切替え可能かつ前回のロールが未設定の場合は未選択状態にする
          userContext.canSwitchRole()
          ? null
          : // ロール切替え不可の場合はログインユーザーのロールを設定
            userContext.hasDriverRole()
            ? DRIVER_ROLE
            : CORE_DELIVERY_ROLE;

  $: formattedToday = formatDate(time, "yyyy年MM月dd日 EEEE", {
    locale: localeJa,
  });
  $: hours = String(time.getHours()).padStart(2, "0"); // 時間
  $: minutes = String(time.getMinutes()).padStart(2, "0"); // 分

  onMount(() => {
    if (appContext.isReadOnlyMode && userContext.canSwitchRole()) {
      // 読取専用モードの場合は宅配業務を選択状態にする
      selectedRole = DRIVER_ROLE;
    }

    if (!$currentPositionStore) {
      // 現在地情報が取得できていない場合は取得可否を再確認
      checkAvailabilityCurrentPosition();
    }

    // 初回表示の場合、かつ宅配業務が可能な場合は複数端末利用についてのダイアログを表示
    if (
      !appContext.isAlreadyDisplayedMultiDeviceDialog &&
      (userContext.canSwitchRole() || userContext.hasDriverRole())
    ) {
      multiDeviceDialog.openDialog();
    }

    // 表示時刻を1秒毎に更新
    const interval = setInterval(() => {
      time = new Date();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  /**
   * 業務開始処理
   */
  async function beginWork() {
    /** @type {DriverType} */
    let driverType;

    if (isReadOnlyMode !== appContext.isReadOnlyMode) {
      // 読取専用モードの設定を保存
      appContext.isReadOnlyMode = isReadOnlyMode;
      appContext.store();
    }

    try {
      if (selectedRole === CORE_DELIVERY_ROLE) {
        // 幹線輸送業務を開始する場合
        if (userContext.hasDriverRole()) {
          // 宅配業務ロールでログイン中の場合ロール切替え
          await switchRole(userContext, CORE_DELIVERY_ROLE);
        }
        driverType = DriverType.CORE_DELIVERY;
      } else {
        // 宅配業務を開始する場合
        if (userContext.hasContractDriverRole()) {
          // 幹線輸送ロールでログイン中の場合ロール切替え
          await switchRole(userContext, DRIVER_ROLE);
        }
        driverType = DriverType.DRIVER;
      }

      if (userContext.canSwitchRole()) {
        // ロール切替え可能な場合は選択したロールを保存
        appContext.previousRole = selectedRole;
        appContext.store();
      }

      // 業務開始登録
      if (!appContext.isReadOnlyMode) {
        await workManage.begin(userContext, driverType);
      }

      // ページ遷移
      if (selectedRole === DRIVER_ROLE) {
        pageRouter.moveToList();
      } else {
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
      }
    } catch (error) {
      if (error instanceof AlreadyBeenStartedException) {
        // 既に業務開始済みの場合、複数端末での利用の可能性があるため、読取専用モードに切替える必要がないか確認する
        multiDeviceDialog.openDialog();
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        // サーバーエラー応答等が発生した場合
        logger.error(
          "[BeginWork] ロール切替えでエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 位置情報の利用可否を判断し、利用できない場合はダイアログを表示する
   */
  async function checkAvailabilityCurrentPosition() {
    geolocator.requestCurrentPosition(false);
    await loadingProgress.wrapAsync(async () => {
      for (let count = 0; count < 10; count++) {
        await sleep(500);
        if ($currentPositionStore) {
          // 位置情報が取得できた場合はダイアログを閉じる
          currentPositionUnavailableDialog.closeDialog();
          return;
        }
      }
      // 位置情報が取得できなかった場合はダイアログを表示
      currentPositionUnavailableDialog.openDialog();
    })();
  }
</script>

<div class="wrapper">
  <button
    class="logout"
    on:click={() => {
      pageRouter.moveToLogin();
    }}
  >
    <span class="material-icons md-18">logout</span>
  </button>

  {#if userContext.canSwitchRole() || userContext.hasDriverRole()}
    <!-- 複数端末使用するユーザーの場合のみ、選択中モードを表示 -->
    <div class="multiDeviceMode">
      <FormField align="end">
        <span slot="label">読取専用モード</span>
        <Switch
          bind:checked={isReadOnlyMode}
          style="margin-left: 9px;"
          on:SMUISwitch:change={(event) => {
            if (userContext.canSwitchRole() && event.detail.selected) {
              // 読取専用モードを選択した場合は宅配業務を選択状態にする
              selectedRole = DRIVER_ROLE;
            }
          }}
        />
      </FormField>
    </div>
  {/if}

  <div class="calenderArea">
    <p class="date">
      {formattedToday}
    </p>
    <div class="clock" role="timer" aria-live="polite" aria-atomic="true">
      {hours}:{minutes}
    </div>
  </div>

  <div class="imageArea">
    {#if selectedRole === CORE_DELIVERY_ROLE}
      <p class="img" transition:fade>
        <img src={sceneImagePickupAndSort} alt="幹線輸送業務" />
      </p>
    {:else}
      <p class="img" transition:fade>
        <img src={sceneImageOutForDelivery} alt="宅配業務" />
      </p>
    {/if}
  </div>

  {#if userContext.canSwitchRole()}
    <div class="roleArea">
      {#if userContext.canSwitchRole() && !appContext.previousRole}
        <p class="roleNotice">どちらの業務を始めるか選択してください</p>
      {/if}
      <div class="roleButtons">
        <SegmentedButton
          segments={switchableRoles}
          let:segment
          singleSelect
          bind:selected={selectedRole}
        >
          <Segment
            style="{isReadOnlyMode && segment === CORE_DELIVERY_ROLE
              ? 'background-color: #a9a9a9; pointer-events: none;'
              : 'background-color: none'};"
            disabled={isReadOnlyMode && segment === CORE_DELIVERY_ROLE}
            {segment}
          >
            <Label>{$_(`classes.businessShortName.${segment}`)}</Label>
          </Segment>
        </SegmentedButton>
      </div>
      {#if isReadOnlyMode}
        <p class="readonlyNotice">
          ※読取専用モードでは幹線輸送業務は行えません
        </p>
      {/if}
    </div>
  {/if}

  <Button
    variant="unelevated"
    color="secondary"
    style="width: 280px; min-height: 60px; margin-top: 25px; font-size: 22px;"
    disabled={selectedRole === null ||
      (isReadOnlyMode && selectedRole === CORE_DELIVERY_ROLE)}
    on:click={loadingProgress.wrapAsync(beginWork)}
  >
    <Label>業務を開始する</Label>
  </Button>

  {#if isReadOnlyMode && selectedRole === CORE_DELIVERY_ROLE}
    <p class="readonlyNotice2">※読取専用モードでは幹線輸送業務は行えません</p>
  {/if}
</div>

<div class="currentPositionUnavailableDialog">
  <ConfirmDialog
    bind:this={currentPositionUnavailableDialog}
    type={ConfirmDialogTypes.NONE}
    mandatory={true}
  >
    <svelte:fragment slot="title">位置情報が取得できません</svelte:fragment>
    <svelte:fragment slot="content">
      <span
        >アプリに位置情報の利用を許可していない場合は、設定を変更してから再読み込みを行ってください。</span
      >

      <Button
        class="registerButton"
        color="secondary"
        variant="outlined"
        on:click={loadingProgress.wrapAsync(checkAvailabilityCurrentPosition)}
        style="width: 100%; margin-top: 15px;"
      >
        <Label>再読み込みを行う</Label>
      </Button>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<div class="multiDeviceDialog">
  <ConfirmDialog
    bind:this={multiDeviceDialog}
    type={ConfirmDialogTypes.NONE}
    mandatory={true}
  >
    <svelte:fragment slot="title"
      >{#if !appContext.isAlreadyDisplayedMultiDeviceDialog}初回{/if}確認</svelte:fragment
    >
    <svelte:fragment slot="content">
      <span>
        {#if !appContext.isAlreadyDisplayedMultiDeviceDialog}
          同時に複数端末でアプリを使用しますか？<span style="font-size:14px;"
            >(配達登録用と地図表示用で2台使う等)</span
          >
        {:else}
          同時に複数端末でアプリを使用していますか？
        {/if}
        <div
          style="display: flex; justify-content: center; margin-left: -20px;"
        >
          <FormField>
            <Radio bind:group={isUseMultiDevice} value={false} />
            <span slot="label" style="font-size: 16px; margin: 0 15px 0 -5px;">
              {#if !appContext.isAlreadyDisplayedMultiDeviceDialog}
                使用しない
              {:else}
                していない
              {/if}</span
            >
          </FormField>
          <FormField>
            <Radio bind:group={isUseMultiDevice} value={true} />
            <span slot="label" style="font-size: 16px; margin: 0 5px 0 -5px;">
              {#if !appContext.isAlreadyDisplayedMultiDeviceDialog}
                使用する
              {:else}
                している
              {/if}</span
            >
          </FormField>
        </div>

        {#if isUseMultiDevice}
          <!-- 複数端末使用する場合の注記＆読取専用モード設定 -->
          <div class="multiDeviceNotice">
            <span
              >配達登録は<b>1台の端末からのみ</b
              >行う必要があります。登録を行わない端末は<b>読取専用モード</b
              >でご使用ください。<br /><span style="font-size:13px;"
                >※この設定はあとから変更できます</span
              ></span
            >
            <FormField>
              <Radio bind:group={isReadOnlyModeTemp} value={false} />
              <span slot="label">この端末で配達登録を行う</span>
            </FormField>
            <FormField>
              <Radio bind:group={isReadOnlyModeTemp} value={true} />
              <span slot="label"
                >この端末で配達登録を行わない<br />(読取専用モードで使う)</span
              >
            </FormField>
          </div>
        {/if}

        <Button
          class="registerButton"
          color="secondary"
          variant="outlined"
          disabled={isUseMultiDevice === undefined ||
            (isUseMultiDevice &&
              isReadOnlyModeTemp !== true &&
              isReadOnlyModeTemp !== false)}
          on:click={() => {
            if (isUseMultiDevice) {
              // 複数端末利用を選択した場合は読取専用モードの選択状態をcontextに保存
              isReadOnlyMode = isReadOnlyModeTemp;
            } else {
              // 複数端末利用を選択しなかった場合は読取専用モードを利用しない
              isReadOnlyMode = false;
            }

            if (!appContext.isAlreadyDisplayedMultiDeviceDialog) {
              // 初回確認の場合

              // 業務開始の重複登録に備えて選択状態をクリア
              isUseMultiDevice = undefined;
              isReadOnlyModeTemp = undefined;

              if (userContext.canSwitchRole() && isReadOnlyMode) {
                // 読取専用モードを選択した場合は宅配業務を選択状態にする
                selectedRole = DRIVER_ROLE;
              }
              // 表示済みフラグを立てる
              appContext.isAlreadyDisplayedMultiDeviceDialog = true;

              // 読取専用モードの選択状態をcontextに保存
              appContext.isReadOnlyMode = isReadOnlyMode;
              appContext.store();

              multiDeviceDialog.closeDialog();
            } else {
              // 業務開始の重複登録をトリガーとした確認の場合

              // 読取専用モードの選択状態をcontextに保存
              appContext.isReadOnlyMode = isReadOnlyMode;
              appContext.store();

              // ページ遷移
              if (selectedRole === DRIVER_ROLE) {
                pageRouter.moveToList();
              } else {
                pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
              }
            }
          }}
          style="width: 100%; margin-top: 15px;"
        >
          <Label>OK</Label>
        </Button>
      </span></svelte:fragment
    >
  </ConfirmDialog>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logout {
      position: fixed;
      border: none;
      background: #dadada;
      top: 15px;
      left: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .multiDeviceMode {
      position: fixed;
      top: 13px;
      right: 0;

      span {
        padding-left: 10px;
      }
    }

    .calenderArea {
      color: #424242;
      text-align: center;
      margin-bottom: 10vh;

      .date {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .clock {
        font-size: 54px;
      }
    }

    .imageArea {
      position: relative;
      width: 100%;
      height: 20vh;

      .img {
        position: absolute;
        width: 100%;
      }

      :global(p.img img) {
        width: 100%;
      }
    }

    .roleArea {
      margin-top: 40px;

      .roleNotice {
        padding-bottom: 10px;
        font-size: 15px;
        color: var(--mdc-theme-error);
      }

      .readonlyNotice {
        font-size: 12px;
        text-align: center;
        color: var(--mdc-theme-error);
        margin-top: 5px;
      }

      .roleButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        :global(.mdc-segmented-button__label) {
          margin: 0 25px;
          font-size: 20px;
        }
      }
    }

    .readonlyNotice2 {
      font-size: 13px;
      text-align: center;
      color: var(--mdc-theme-error);
      margin-top: 6px;
    }
  }

  .multiDeviceDialog {
    .multiDeviceNotice {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgb(252, 230, 230);
      padding: 10px 12px;
      margin-top: 10px;
      border-radius: 5px;
      line-height: 1.4;

      span {
        font-size: 14px;
      }
    }
  }
</style>
