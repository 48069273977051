import { HTTPError } from "ky";

import backendApi from "~/libs/backendApi";
import { BackendApiName } from "~/libs/constants";
import { IndexedDbConnectErrorException, db } from "~/libs/db";
import logger from "~/libs/logger";

const requestsQueueForRetry = {
  /**
   * 配送ステータスの更新
   * @param {string} trackingNumber
   * @param {[string, FormDataEntryValue][]} data
   * @param {string} username
   * @returns {Promise<string>} 登録時に自動採番されたID
   */
  async updateShipmentStatus(trackingNumber, data, username) {
    return await addRequestsQueue(
      BackendApiName.UPDATE_SHIPMENT_STATUS,
      trackingNumber,
      data,
      username,
    );
  },

  /**
   * idを指定して送信待ちリクエストを再送する
   * @param {string} id
   * @param {string} username
   * @returns {Promise<{
   *   trackingNumber: string, // 更新対象の送り状番号
   *   updateResponse: import("~/libs/backendApi").UpdateShipmentStatusResponse, // 更新結果
   *   updateRequestJson: object, // 更新リクエストのJSON
   * }>}
   */
  async resendById(id, username) {
    try {
      // DBから該当のリクエストを取得
      const request = await this.getRequestsById(id, username);

      // リクエストからJSON部分(status-update-event)を抽出
      let updateRequestJson;
      if (request.data[0][0] == "status-update-event") {
        updateRequestJson = JSON.parse(
          await request.data[0][1].text().then((json) => json),
        ).events[0];
      } else {
        updateRequestJson = JSON.parse(
          await request.data[1][1].text().then((json) => json),
        ).events[0];
      }

      // API実行（現状はステータス更新APIのみ対応）
      const updateResponse = await execUpdateShipmentStatusApi(request);

      return {
        trackingNumber: request.trackingNumber,
        updateResponse,
        updateRequestJson,
      };
    } catch (error) {
      console.log(error);
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 401
      ) {
        // 呼び出し元で401エラーを検知できるよう、エラーをスローする
        throw error;
      } else {
        throw new IndexedDbConnectErrorException();
      }
    }
  },

  /**
   * idを指定してリクエスト情報を取得する。
   * @param {string} id
   * @param {string} username
   * @returns {Promise<import("~/libs/db").RequestsQueueForRetry>}
   */
  async getRequestsById(id, username) {
    try {
      return await db.requestsQueueForRetry.where("id").equals(id).first();
    } catch (error) {
      // リクエスト情報の取得に失敗した場合、ログを表示して継続
      logger.error(
        "[requestsQueueForRetry] リクエスト情報の取得に失敗しました。",
        {
          username: username,
        },
        error,
      );
    }
  },

  /**
   * idを指定してリクエスト情報を削除する。
   * @param {string} id
   * @param {string} username
   */
  async deleteRequestsById(id, username) {
    try {
      await db.requestsQueueForRetry.delete(id);
    } catch (error) {
      // リクエスト情報の取得に失敗した場合、ログを表示して継続
      logger.error(
        "[requestsQueueForRetry] リクエスト情報の削除に失敗しました。",
        {
          username: username,
        },
        error,
      );
    }
  },
};
export default requestsQueueForRetry;

/**
 * indexedDBにリクエスト情報を登録する。
 * @param {string} backendApiName
 * @param {string} trackingNumber
 * @param {object} data
 * @param {string} username
 * @returns {Promise<string>} 登録時に自動採番されたID
 */
async function addRequestsQueue(
  backendApiName,
  trackingNumber,
  data,
  username,
) {
  try {
    const record = {
      trackingNumber: trackingNumber,
      backendApiName: backendApiName,
      data: data,
    };

    return await db.requestsQueueForRetry.add(record);
  } catch (error) {
    // リクエスト情報の登録に失敗した場合、ログを表示してエラーをスローする
    logger.error(
      "[requestsQueueForRetry] リクエスト情報の登録に失敗しました。",
      {
        username: username,
      },
      error,
    );
    throw error;
  }
}

/**
 * ステータス更新APIを実行
 * @param {import("~/libs/db").RequestsQueueForRetry} request
 * @returns {Promise<object>}
 */
async function execUpdateShipmentStatusApi(request) {
  const newBody = new FormData();
  for (let [key, value] of request.data) {
    newBody.append(key, value);
  }
  const response = await backendApi.updateShipmentStatus(newBody);
  return response;
}
