<script>
  import Button from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import PasswordStrengthChecker from "~/components/PasswordStrengthChecker.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    PasswordStrength,
  } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 現在のパスワード */
  let currentPw = "";
  /** 現在のパスワードのtype属性値 */
  let currentPwType = "password";
  /** 新しいパスワード */
  let newPw1 = "";
  /** 新しいパスワードのtype属性値 */
  let newPw1Type = "password";
  /** 新しいパスワード（再入力） */
  let newPw2 = "";
  /** 新しいパスワード（再入力）のtype属性値 */
  let newPw2Type = "password";

  /** パスワード更新処理でエラーが発生した場合の表示メッセージ（エラーが発生していない場合は真ではない値） @type {string} */
  let errorMessage;

  /** 新しいパスワードの強度 @type {PasswordStrength} */
  let passwordStrength;

  /** 更新確認ダイアログ @type {ConfirmDialog} */
  let updateConfirmDialog;

  /** 「パスワードを変更」ボタンの有効化フラグ */
  $: changePasswordButtonDisabled = !(
    currentPw &&
    newPw1 &&
    newPw2 &&
    passwordStrength === PasswordStrength.STRONG &&
    newPw1 === newPw2
  );

  function assertNewPassword() {
    if (newPw1 !== newPw2) {
      throw new HandledError($_("errors.mismatchPassword"));
    }
    if (currentPw === newPw1) {
      throw new HandledError($_("errors.matchOldPassword"));
    }
  }

  const changePassword = loadingProgress.wrapAsync(async () => {
    errorMessage = null;
    try {
      await backendApi.changePassword({
        oldPassword: currentPw,
        newPassword: newPw1,
      });

      // iOSネイティブアプリの場合は認証情報の保存をサジェストさせる
      iosNativeApp.saveCredentials(userContext.loginUser.username, newPw1);

      toast.info($_("message.pwChangeComplete"));
      pageRouter.moveToOtherMenu();
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401 &&
        Date.now() > userContext.loginUser?.refreshExpires
      ) {
        // 401エラーかつリフレッシュトークンが期限切れの場合、
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      } else {
        // それ以外の場合
        // （401エラーでもリフレッシュトークンが切れていない場合はID/PWの誤りによるエラー）
        showErrorMessage(error);
      }
    }
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      errorMessage = error.message;
    } else {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        errorMessage = $_("errors.loginFailed"); // TODO: 不適切なメッセージ（ログイン用を流用）
      } else {
        logger.error(
          "[PasswordChange] パスワードの変更でエラーが発生しました",
          { username: userContext.loginUser?.username },
          error,
        );
        errorMessage = $_("errors.loginDefaultMessage"); // TODO: 不適切なメッセージ（ログイン用を流用）
      }
    }
  }

  function confirmBeforeUpdate() {
    try {
      assertNewPassword();
      updateConfirmDialog.openDialog();
    } catch (error) {
      showErrorMessage(error);
    }
  }

  /**
   * 入力フィールドでEnterキーを押下した時に「パスワードを変更」ボタンの処理を実行するイベントハンドラ。
   * @param {KeyboardEvent} event
   */
  function onEnterKeyDownHandler(event) {
    if (event.key === "Enter" && !changePasswordButtonDisabled) {
      confirmBeforeUpdate();
    }
  }
</script>

<div class="mainContentsWrapper">
  <!-- ヘッダー -->
  <Header>
    <svelte:fragment slot="center">パスワードの変更</svelte:fragment>
  </Header>

  <main in:fade>
    <div class="wrapper">
      <div class="inputField">
        <Textfield
          type="text"
          label="ユーザーID"
          value={userContext.loginUser?.username ?? ""}
          variant="outlined"
          style="margin-top: 10px;"
          input$id="username"
          input$name="username"
          input$autocomplete="username"
          input$readonly
        />
      </div>
      <div class="inputField passwordField">
        <Textfield
          type={currentPwType}
          label="現在のパスワード"
          variant="outlined"
          style="margin-top: 15px;"
          required
          input$id="current-password"
          input$name="current-password"
          input$autocomplete="current-password"
          bind:value={currentPw}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              currentPwType = currentPwType == "text" ? "password" : "text";
            }}
          >
            {currentPwType == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>
      </div>
      <div class="inputField passwordField">
        <Textfield
          type={newPw1Type}
          label="新しいパスワード"
          variant="outlined"
          style="margin-top: 15px;"
          required
          input$id="new-password"
          input$name="new-password"
          input$autocomplete="new-password"
          bind:value={newPw1}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              newPw1Type = newPw1Type == "text" ? "password" : "text";
            }}
          >
            {newPw1Type == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>
      </div>
      <div class="inputField passwordField">
        <Textfield
          type={newPw2Type}
          label="新しいパスワード（再入力）"
          variant="outlined"
          style="margin-top: 15px;"
          required
          input$id="new-password-2"
          input$name="new-password"
          input$autocomplete="new-password"
          bind:value={newPw2}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              newPw2Type = newPw2Type == "text" ? "password" : "text";
            }}
          >
            {newPw2Type == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>
      </div>

      <div class="passwordStrengthChecker">
        <PasswordStrengthChecker password={newPw1} bind:passwordStrength />
      </div>

      {#if errorMessage}
        <p class="errorMessage">{errorMessage}</p>
      {/if}

      <div class="command">
        <Button
          type="submit"
          variant="unelevated"
          style="width: 160px; height: 50px;"
          touch
          bind:disabled={changePasswordButtonDisabled}
          on:click={confirmBeforeUpdate}
          >パスワードを変更
        </Button>
      </div>
    </div>
  </main>

  <Footer />
</div>

<div class="subContentsWrapper">
  <!-- 更新確認ダイアログ -->
  <ConfirmDialog
    bind:this={updateConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        changePassword();
      }
    }}
  >
    <svelte:fragment slot="title">注意事項</svelte:fragment>
    <svelte:fragment slot="content"
      >ブラウザにパスワードを記憶させている場合は、新しいパスワードを忘れずに反映してください。</svelte:fragment
    >
  </ConfirmDialog>
</div>

<style lang="scss">
  main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    padding: 30px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .inputField {
    :global(.mdc-text-field) {
      width: 280px;
      background-color: #fff;
    }
  }

  .passwordField {
    :global(.mdc-icon-button) {
      margin: auto 0;
    }
  }

  .passwordStrengthChecker {
    margin-top: 5px;
  }

  .command {
    margin-top: 30px;
  }

  .errorMessage {
    margin-top: 8px;
    color: rgb(228, 4, 4);
    font-size: 14px;
    font-weight: 600;
  }
</style>
