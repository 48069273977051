<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import Radio from "@smui/radio";
  import Textfield from "@smui/textfield";
  import { format as formatDate } from "date-fns";
  import { _ } from "svelte-i18n";

  import { DropOffLocation } from "~/libs/constants";

  /** @type {Array<{deliveryMethod: number, selectable: boolean, receiverSelectable: boolean}>} 受渡方法のリスト */
  export let selectableList;
  /** @type {number} 荷受け人が希望した受け渡し方法 */
  export let desiredPackageDropPlace;
  /** @type {string} 荷受け人の電話番号 */
  export let receiverTel;
  /** @type {string} 連絡方法（コンポーネント間連携用） */
  export let contactMethod;
  /** @type {string} 指定された置き配場所（コンポーネント間連携用） */
  export let designatedDropPlace;
  /** @type {string} 指示内容を登録した日時（コンポーネント間連携用） */
  export let agreedAt;
  export let selectOther;

  const ContactMethod = {
    TEL: "1",
    INTERCOM: "2",
    OTHER: "-1",
  };
  const DesignatedDropPlace = {
    OTHER: "-1",
  };

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpened = false;

  /** @type {boolean} ESCキーやblurでダイアログを閉じないようにするか否か */
  let mandatory = true;

  /**
   * @callback onDialogClosedCallback
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  /**
   * @type {onDialogClosedCallback}
   */
  let onDialogClosedHandler = () => {};

  /** @type {string} 連絡方法の選択値 */
  let contactMethodChoice = "";

  /** @type {string} その他連絡方法の記入欄 */
  let otherInputFieldOfContactMethod = "";

  /**@type {string} 指定された置き配場所の選択値 */
  let designatedDeliveryLocationChoice = "";

  /** @type {string} その他指定された置き配場所の記入欄 */
  let otherInputFieldOfDesignatedDeliveryLocation = "";

  /** @type {boolean} 「置き配として配達を継続する」ボタンの活性状態 */
  $: buttonAbility =
    (contactMethodChoice === "その他"
      ? Boolean(otherInputFieldOfContactMethod)
      : Boolean(contactMethodChoice)) &&
    (designatedDeliveryLocationChoice === "その他"
      ? Boolean(otherInputFieldOfDesignatedDeliveryLocation)
      : Boolean(designatedDeliveryLocationChoice));

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    contactMethodChoice =
      contactMethod === ContactMethod.TEL ||
      contactMethod === ContactMethod.INTERCOM
        ? contactMethod
        : contactMethod
          ? ContactMethod.OTHER
          : "";
    designatedDeliveryLocationChoice =
      designatedDropPlace === String(DropOffLocation.BY_THE_DOOR) ||
      designatedDropPlace === String(DropOffLocation.METERBOX)
        ? designatedDropPlace
        : designatedDropPlace
          ? DesignatedDropPlace.OTHER
          : "";

    dialogOpened = true;
  }

  function closeDialog() {
    dialogOpened = false;
  }

  /**
   * 置き配として配達を継続する
   */
  function continueDelivery() {
    if (contactMethodChoice !== ContactMethod.OTHER) {
      contactMethod = contactMethodChoice;
    } else {
      contactMethod = otherInputFieldOfContactMethod;
    }

    if (designatedDeliveryLocationChoice !== DesignatedDropPlace.OTHER) {
      designatedDropPlace = designatedDeliveryLocationChoice;
    } else {
      designatedDropPlace = otherInputFieldOfDesignatedDeliveryLocation;
    }

    agreedAt = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");

    selectOther();
    closeDialog();
  }
</script>

<div class="priorAgreementForDropPlaceDialog">
  <Dialog
    bind:open={dialogOpened}
    scrimClickAction={mandatory ? "" : "close"}
    escapeKeyAction={mandatory ? "" : "close"}
    on:SMUIDialog:closed={onDialogClosedHandler}
  >
    <Header>
      <Title>その他場所に置き配する場合</Title>
      <IconButton
        action="close"
        ripple={false}
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>
    <Content>
      <p class="description">
        事前に荷受け人への確認が必要です。<br />
        配達完了前に荷受け人に連絡を取り、置き配場所について指示を受けてください。
      </p>
      <ul class="note">
        <li>
          <div>※</div>
          <div>登録した内容は管理者、荷受け人から確認可能となります。</div>
        </li>
      </ul>
      <section>
        <h3>1. 連絡方法を選択する</h3>
        <div class="contactMethod">
          {#if receiverTel}
            <FormField>
              <Radio
                bind:group={contactMethodChoice}
                value={ContactMethod.TEL}
              />
              <span slot="label"
                >{$_(`classes.contactMethod.${ContactMethod.TEL}`)}</span
              >
            </FormField>
            <button
              class="callButton"
              on:click={() => {
                window.location.href = `tel:${receiverTel}`;
              }}
              ><span class="material-icons">call</span><span>電話をかける</span
              ></button
            >
          {/if}
          <FormField>
            <Radio
              bind:group={contactMethodChoice}
              value={ContactMethod.INTERCOM}
            />
            <span slot="label"
              >{$_(`classes.contactMethod.${ContactMethod.INTERCOM}`)}</span
            >
          </FormField>
          <FormField>
            <Radio
              bind:group={contactMethodChoice}
              value={ContactMethod.OTHER}
            />
            <span slot="label">その他</span>
          </FormField>
          <div
            class="otherInputArea"
            class:hide={contactMethodChoice !== ContactMethod.OTHER}
          >
            <Textfield
              type="text"
              label="その他の連絡方法"
              bind:value={otherInputFieldOfContactMethod}
              variant="outlined"
              input$placeholder="例) 対面"
              style="margin-top: 5px;"
            />
          </div>
        </div>
      </section>
      <section>
        <h3>2. 指示された置き配場所を選択する</h3>
        <div class="designatedDeliveryLocation">
          {#each selectableList as selectableMethod}
            {#if selectableMethod.receiverSelectable && !selectableMethod.selectable && selectableMethod.deliveryMethod !== desiredPackageDropPlace}
              <FormField>
                <Radio
                  bind:group={designatedDeliveryLocationChoice}
                  value={String(selectableMethod.deliveryMethod)}
                />
                <span slot="label"
                  >{$_(
                    `classes.packageDropPlace.${selectableMethod.deliveryMethod}`,
                  )}</span
                >
              </FormField>
            {/if}
          {/each}
          <FormField>
            <Radio
              bind:group={designatedDeliveryLocationChoice}
              value={DesignatedDropPlace.OTHER}
            />
            <span slot="label">その他</span>
          </FormField>
          <div
            class="otherInputArea"
            class:hide={designatedDeliveryLocationChoice !==
              DesignatedDropPlace.OTHER}
          >
            <Textfield
              type="text"
              label="その他の置き配場所"
              bind:value={otherInputFieldOfDesignatedDeliveryLocation}
              variant="outlined"
              input$placeholder="例) 自転車のカゴ"
              style="margin-top: 5px;"
            />
          </div>
        </div>
      </section>
      <div class="buttonArea">
        <Button
          color="secondary"
          variant="unelevated"
          disabled={!buttonAbility}
          on:click={continueDelivery}
          action="ok"
        >
          <Label>置き配として配達を継続する</Label>
        </Button>
      </div>
    </Content>
  </Dialog>
</div>

<style lang="scss">
  .priorAgreementForDropPlaceDialog {
    text-align: left;

    :global(.mdc-radio) {
      padding: 8px;
    }
    :global(.mdc-text-field) {
      margin-left: 35px;
      width: calc(100% - 35px);
    }
    :global(.mdc-text-field--outlined) {
      height: 40px;
    }
    :global(
      .mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above
    ) {
      transform: translateY(-28px) scale(0.75);
    }

    .hide {
      display: none;
    }

    .description {
      line-height: 1.4;
      font-size: 15px;
    }

    .note {
      color: #f00;
      display: table;
      font-size: 13px;
      line-height: 1.2;
      margin-top: 5px;

      li {
        list-style: none;

        div {
          display: table-cell;
        }
      }
    }

    section {
      margin-top: 15px;
    }

    .contactMethod,
    .designatedDeliveryLocation {
      display: flex;
      flex-flow: column;
    }

    .callButton {
      padding: 5px 0;
      background-color: #fff;
      border: 1px solid #018786;
      border-radius: 4px;
      margin-top: -5px;
      margin-left: 35px;
      width: calc(100% - 35px);

      span {
        font-size: 15px;
        color: #018786;

        &.material-icons {
          font-size: 20px;
          margin-right: 3px;
          vertical-align: middle;
        }
      }
    }

    .buttonArea {
      margin-top: 20px;

      :global(.mdc-button) {
        width: 100%;
        height: 50px;
      }
      :global(.mdc-button:not(:first-of-type)) {
        margin-top: 20px;
      }
    }
  }
</style>
