<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { createEventDispatcher, onMount } from "svelte";

  const dispatch = createEventDispatcher();

  /** @type {number}  宅配ボックス情報のインデックス */
  export let indexOfLockerInfo;
  /** @type {Array<string>} 宅配ボックスのボックス番号リスト */
  export let lockerNumberList;
  /** @type {Array<string>} 宅配ボックスの暗証番号リスト */
  export let lockerPinList;

  /** @type {string} 宅配ボックスのボックス番号（画面表示用） */
  let displayLockerNumber = lockerNumberList[indexOfLockerInfo] || "";
  /** @type {string} 宅配ボックスの暗証番号（画面表示用） */
  let displayLockerPin = lockerPinList[indexOfLockerInfo] || "";

  /** @type {boolean} ボックス番号の入力有無（チェックボックス） */
  let enableInputLockerNumber = true;
  /** @type {boolean} 暗証番号の入力有無（チェックボックス） */
  let enableInputLockerPin = true;

  // テキストボックスの入力値をコンポーネント間連携用の変数に同期
  $: lockerNumberList[indexOfLockerInfo] = enableInputLockerNumber
    ? displayLockerNumber
    : null;
  $: lockerPinList[indexOfLockerInfo] = enableInputLockerPin
    ? displayLockerPin
    : null;

  onMount(() => {
    displayLockerPin = getRamdomPin();
  });

  function getRamdomPin() {
    return String(Math.floor(Math.random() * 9999)).padStart(4, "0");
  }

  /**
   * ボックス番号選択ボタンのクリックイベント
   * @param {Event} event
   */
  function onClickLockerNumberButton(event) {
    const button = /** @type {HTMLButtonElement} */ (event.target);
    if (displayLockerNumber == button.textContent) {
      displayLockerNumber = "";
    } else {
      displayLockerNumber = button.textContent;
    }
  }

  function deleteLockerInfo() {
    dispatch("deleteLockerInfo", indexOfLockerInfo);
  }
</script>

<div class="lockerArea">
  <div class="lockerAreaHeader">
    <span
      >{#if indexOfLockerInfo > 0}{indexOfLockerInfo +
          1}個目の{/if}宅配ボックス情報を登録</span
    >
    {#if indexOfLockerInfo > 0}
      <Button
        color="secondary"
        variant="unelevated"
        style="font-size: .8rem; padding: 2px 8px; min-width: 20px; height: auto;"
        on:click={deleteLockerInfo}>削除</Button
      >
    {/if}
  </div>
  <!-- ボックス番号 -->
  <div class="lockerNumberArea">
    <p class="lockerNumberAreaHeader">
      <FormField>
        <Checkbox bind:checked={enableInputLockerNumber} />
        <span slot="label">ボックス番号を指定</span>
      </FormField>
    </p>
    {#if enableInputLockerNumber}
      <div class="lockerNumberButtons">
        {#each [...Array(50).keys()] as i}
          <button
            class:current={displayLockerNumber === String(i + 1)}
            on:click={onClickLockerNumberButton}
            disabled={displayLockerNumber !== String(i + 1) &&
              lockerNumberList.includes(String(i + 1))}>{i + 1}</button
          >
        {/each}
      </div>
      <input
        type="text"
        class="lockerNumber"
        maxlength="4"
        placeholder="その他番号"
        bind:value={displayLockerNumber}
      />
    {/if}
  </div>

  <hr />

  <!-- 暗証番号 -->
  <div class="lockerPinArea">
    <p class="lockerPinAreaHeader">
      <FormField>
        <Checkbox bind:checked={enableInputLockerPin} />
        <span slot="label">暗証番号を指定</span>
      </FormField>
    </p>
    {#if enableInputLockerPin}
      <input
        type="text"
        class="lockerPin"
        maxlength="6"
        bind:value={displayLockerPin}
      />
    {/if}
  </div>
</div>

<style lang="scss">
  .lockerArea {
    display: flex;
    flex-direction: column;
    margin: 15px 10px 0;
    padding-bottom: 10px;
    border: solid 0.5px #b2b2b2;

    input[type="text"] {
      width: 60%;
    }

    hr {
      margin: 15px 0 0;
      border-top: solid 0.5px #b2b2b2;
    }
  }

  .lockerAreaHeader {
    background-color: #e7f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    text-align: left;
    line-height: 1.5rem;
  }

  .lockerNumberArea {
    padding: 0 15px;

    .lockerNumberAreaHeader {
      text-align: left;
      margin-left: -10px;
    }

    // ボックス番号選択ボタン
    .lockerNumberButtons {
      display: flex;
      align-items: center;
      gap: 8px;
      overflow-x: scroll;

      button {
        min-width: 30px;
        min-height: 30px;
        padding: 0;
        border: none;
        border-radius: 3px;
        background-color: #e9e9e9;
        font-size: 15px;
        font-weight: 500;
        color: #333;

        &.current {
          background-color: #018786;
          color: #fff;
        }
      }
      button[disabled] {
        background-color: #ccc;
      }
    }

    input.lockerNumber {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
    }
  }

  .lockerPinArea {
    padding: 0 15px;

    .lockerPinAreaHeader {
      text-align: left;
      margin-left: -10px;
    }

    input.lockerPin {
      text-align: center;
      font-size: 16px;
    }
  }
</style>
