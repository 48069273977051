<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import IconButton from "@smui/icon-button";
  import Tooltip, { Content as TooltipContent, Wrapper } from "@smui/tooltip";
  import { HTTPError } from "ky";
  import QRCode from "qrcode";

  import backendApi from "~/libs/backendApi";
  import { WorkTakeoverTypes } from "~/libs/constants";

  /** 輸送中荷物の情報 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  export let inTransitDeliveryList;

  /** 引継が完了したときに呼び出されるコールバック @type {() => void} */
  export let onTakeoverCompletedCallback;

  /** 引継要求ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** 引継対象の輸送中荷物 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  let takeoverItems;

  /** 引継用URI @type {string} */
  let takeoverUri;

  /** 引継用QRコードのデータURL @type {string} */
  let takeoverQrCodeDataUrl;

  /** 引継完了フラグ */
  let takeoverCompleted = false;

  /** 対面以外での引継ぎフラグ */
  let nonFaceToFace = false;

  /**
   * 輸送中の荷物の引継要求ダイアログを開く。
   * @param {number} transportDestinationId 幹線輸送先の配送センターID
   */
  export async function openDialog(transportDestinationId) {
    takeoverItems = inTransitDeliveryList
      .map((e1) => ({
        transportSourceId: e1.transportSourceId,
        deliveryInfoList: e1.deliveryInfoList
          .filter((e2) => e2.transportDestinationId === transportDestinationId)
          .map((e2) => ({
            transportDestinationId: e2.transportDestinationId,
            basketCarList: e2.basketCarList
              .filter((e3) => e3.c)
              .map((e3) => ({ k: e3.k, v: e3.v })),
          }))
          .filter((e2) => e2.basketCarList.length > 0),
      }))
      .filter((e1) => e1.deliveryInfoList.length > 0);

    try {
      takeoverUri = await backendApi.postWorkTakeover(
        WorkTakeoverTypes.IN_TRANSIT_PACKAGES,
        takeoverItems,
      );
      takeoverQrCodeDataUrl = await QRCode.toDataURL(takeoverUri, {
        margin: 0,
        scale: 9,
      });
      dialogOpend = true;
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      }
    }
  }

  /**
   * 輸送中の荷物の引継要求ダイアログを閉じる。
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  function onCloseDialog(event) {
    if (event.detail?.action === "ok") {
      // 親ページの引継完了コールバックを呼び出し
      onTakeoverCompletedCallback();
    }

    takeoverItems = undefined;
    takeoverQrCodeDataUrl = undefined;
    takeoverCompleted = false;
  }

  function copyUriCode() {
    try {
      navigator.clipboard.writeText(takeoverUri);
    } catch (error) {
      console.error(error); // use non-logger explicitly
      alert("コードをコピーできませんでした。");
    }
  }
</script>

<div class="requestTakeoverDialog">
  <Dialog
    bind:open={dialogOpend}
    class="wideWidthMdcDialog"
    scrimClickAction={""}
    escapeKeyAction={""}
    on:SMUIDialog:closed={onCloseDialog}
  >
    <Header>
      <Title>輸送中の荷物の引継</Title>
      <IconButton
        action="close"
        ripple={false}
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>

    <Content>
      {#if !nonFaceToFace}
        <span class="description">
          引継先アプリの荷受け画面で以下のQRコードをスキャンしてもらい、引継が完了したことを確認のうえ続行してください。
        </span>
        <div class="methodChangeArea">
          <button
            on:click={() => {
              nonFaceToFace = true;
            }}>非対面で引継ぎを行う場合はこちら</button
          >
        </div>

        <div class="qrcode">
          <img src={takeoverQrCodeDataUrl} alt="QRコード" />
        </div>

        <FormField>
          <Checkbox bind:checked={takeoverCompleted} />
          <span slot="label">引継が完了していることを確認した</span>
        </FormField>
      {:else}
        <span class="description">
          以下の引継ぎコードを引継先に伝えたうえで、引継登録を完了してください。
        </span>
        <div class="methodChangeArea">
          <button
            on:click={() => {
              nonFaceToFace = false;
            }}>対面で引継ぎを行う場合はこちら</button
          >
        </div>

        <div class="uriCode">
          <span>{takeoverUri}</span>
        </div>
        <div class="buttonArea">
          <Wrapper rich>
            <button
              class="copyUriCodeButton"
              on:click|stopPropagation={copyUriCode}
            >
              <span class="material-icons">content_copy</span>
              <span>引継ぎコードをコピー</span>
            </button>
            <Tooltip
              style="top: unset; bottom: 26px; transform: translateX(calc(-50% - 87px));"
            >
              <TooltipContent>クリップボードにコピーしました</TooltipContent>
            </Tooltip>
          </Wrapper>
        </div>

        <FormField>
          <Checkbox bind:checked={takeoverCompleted} />
          <span slot="label">引継ぎコードを引継先に共有した</span>
        </FormField>
      {/if}
    </Content>

    <Actions>
      {#if !nonFaceToFace}
        <Button variant="unelevated" action="ok" disabled={!takeoverCompleted}>
          <Label>引継が完了した荷物を削除する</Label>
        </Button>
      {:else}
        <Button variant="unelevated" action="ok" disabled={!takeoverCompleted}>
          <Label>引継登録を完了して荷物を削除する</Label>
        </Button>
      {/if}
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .requestTakeoverDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-bottom: 6px;
    }

    .description {
      line-height: 1.4;
      font-size: 15px;
    }

    .methodChangeArea {
      text-align: right;
      margin: 0 0 5px;

      button {
        padding: 0;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        background: none;
        border: none;
        text-decoration: underline;
      }
    }

    .qrcode {
      text-align: center;
      margin-top: 8px;
    }

    .uriCode {
      border-radius: 5px;
      border: 2px solid #c2c2c2;
      padding: 8px 20px;
      text-align: left;
      margin-top: 8px;
      font-size: 18px;
    }

    .buttonArea {
      display: flex;
      justify-content: right;

      .copyUriCodeButton {
        display: flex;
        align-items: center;
        gap: 1px;
        padding: 1px 4px;
        background-color: #fff;
        color: #018786;
        border: 1px solid #018786;
        border-radius: 4px;

        span {
          font-size: 11px;

          &.material-icons {
            font-size: 14px;
          }
        }
      }
    }

    :global(.mdc-dialog__actions) {
      margin: 0 12px 10px;
      border-top: 1px solid #ddd;
    }
    :global(.mdc-dialog__actions .mdc-button) {
      margin-top: 8px;
    }
  }
</style>
