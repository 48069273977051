<script>
  import Button, { Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOfflineMode from "~/components/help/HelpOfflineMode.svelte";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    DISABLE_ROLES,
    DRIVER_ROLE,
    OfflineModeTypes,
  } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import offlineBackendApi from "~/libs/offlineBackendApi";
  import pageRouter from "~/libs/pageRouter";
  import { displayOfflineModeHelp } from "~/libs/stores";
  import { switchRole } from "~/libs/switchRole";
  import { toast } from "~/libs/toast";

  /** ユーザーID @type {string} */
  export let userId;
  /** パスワード @type {string} */
  export let pw;
  /** パスワードリセットページを表示するか否か @type {boolean} */
  export let showsPasswordResetPage;

  /** 追加認証ダイアログを閉じる関数 @type {Function} */
  export let closeDialog;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * 切り替え可能なロールのリスト
   * 現時点では固定で宅配業務と幹線輸送業務のみ
   * @type {Array<DRIVER_ROLE | CORE_DELIVERY_ROLE>}
   */
  let switchableRoles = [DRIVER_ROLE, CORE_DELIVERY_ROLE];

  /** 選択中ロールのindex @type {number} */
  let selectedRoleIndex;

  /** ログアウトへの導線を表示するかどうか */
  let showsLogoutFlow = false;

  /** パスワードのtype属性値 */
  let pwType = "password";

  /** エラーメッセージ @type {string} */
  let errorMessage;

  /** オフラインモードヘルプを表示するか否か */
  let showsOfflineModeHelp = false;

  /** @type {{username: string, password: string}} キュー保存用のログインAPIリクエスト */
  let loginApiRequestForOfflineMode;

  /** displayOfflineModeHelpのUnsubscriver @type {import("svelte/store").Unsubscriber} */
  const displayOfflineModeHelpUnsubscriber = displayOfflineModeHelp.subscribe(
    (display) => {
      if (display) {
        showsOfflineModeHelp = true;
      }
    },
  );

  onMount(() => {
    selectedRoleIndex = userContext.loginUser.roles[0] === DRIVER_ROLE ? 0 : 1;
  });

  onDestroy(() => {
    displayOfflineModeHelpUnsubscriber?.();
  });

  /** 「ログイン」ボタンのdisabled属性を有効にするか否か */
  $: loginButtonDisabled = !(userId && pw);

  const login = loadingProgress.wrapAsync(async () => {
    if (!isSameUser()) {
      // 以前ログインしていたユーザーと異なるユーザーIDが入力された場合、ログアウトへの導線を表示する
      showsLogoutFlow = true;
      return;
    }

    try {
      const responseBody = await backendApi.login({
        username: userId,
        password: pw,
      });
      if (DISABLE_ROLES.includes(responseBody.roles[0])) {
        throw new HandledError($_("errors.disableRoles"));
      } else {
        // iOSネイティブアプリの場合は認証情報の保存をサジェストさせる
        iosNativeApp.saveCredentials(userId, pw);

        const currentTime = Date.now();
        userContext.loginUser = {
          username: responseBody.username,
          roles: responseBody.roles,
          accessToken: responseBody.accessToken,
          refreshToken: responseBody.refreshToken,
          expiresIn: responseBody.expiresIn,
          refreshExpires:
            responseBody.refreshExpiresIn > 0
              ? currentTime + responseBody.refreshExpiresIn * 1000
              : undefined,
          loginTime: currentTime,
          displayName: responseBody.displayName,
          companyId: responseBody.companyId,
          companyName: responseBody.companyName,
          emailAddress: responseBody.emailAddress,
          switchableRoles: responseBody.switchableRoles,
          switchableCompanies: responseBody.switchableCompanies,
        };

        // ロール変更されている場合は再度ロール変更を行う
        if (
          userContext.loginUser.roles[0] !== switchableRoles[selectedRoleIndex]
        ) {
          await switchRole(userContext, switchableRoles[selectedRoleIndex]);
        }

        closeDialog();
        appContext.store();
        userContext.store();
      }
      errorMessage = null;
    } catch (error) {
      if (
        error instanceof HandledError ||
        (error instanceof HTTPError &&
          error.response &&
          (error.response.status == 401 || error.response.status == 400))
      ) {
        // 認証エラー応答等を受信した場合、エラーメッセージを表示
        showErrorMessage(error);
      } else {
        try {
          // オフライン状態やサーバーエラー応答等が発生した場合
          // IDを基にロールを判定
          const role = judgeUserRole(userId);

          // ロールに応じて閉塞フラグを確認し、オフラインモード切替えが可能かを判定
          if (
            (role == CORE_DELIVERY_ROLE &&
              import.meta.env.VITE_DISABLED_OFFLINE_MODE_PICKUP_AND_SORT !==
                "true") ||
            (role == DRIVER_ROLE &&
              import.meta.env.VITE_DISABLED_OFFLINE_MODE_DELIVERED !== "true")
          ) {
            // オフラインモード切替えが可能な場合
            // ログインAPIのリクエストを保持して、オフラインモード切替えヘルプを表示
            loginApiRequestForOfflineMode = {
              username: userId,
              password: pw,
            };
            if (error instanceof OfflineException) {
              toast.recommendOfflineMode($_("errors.offline"));
            } else {
              toast.recommendOfflineMode($_("errors.defaultMessage"));
            }
          } else {
            // オフラインモード切替えが不可の場合
            // 認証失敗のエラーメッセージを表示
            showErrorMessage(error);
          }
        } catch (error) {
          showErrorMessage(error);
        }
      }
    }
  });

  const logout = () => {
    closeDialog();
    pageRouter.moveToLogin();
  };

  /**
   * 以前ログインしていたユーザーと同じユーザーIDが入力されたかをチェックする
   * @returns {boolean}
   */
  function isSameUser() {
    return userContext.loginUser?.username === userId;
  }

  /**
   * @param {KeyboardEvent} event
   */
  function onEnterKeyDownHandler(event) {
    if (event.key === "Enter" && !loginButtonDisabled) {
      login();
    }
  }

  /**
   * オフラインモードへの切替えを行う。
   */
  function switchOfflineMode() {
    try {
      const role = judgeUserRole(loginApiRequestForOfflineMode.username);
      appContext.offlineMode = true;
      offlineBackendApi.login(loginApiRequestForOfflineMode);
      if (role == CORE_DELIVERY_ROLE) {
        appContext.offlineModeType = OfflineModeTypes.PICKUP_AND_SORT;
        pageRouter.moveToOfflineMode(OfflineModeTypes.PICKUP_AND_SORT);
      } else {
        appContext.offlineModeType = OfflineModeTypes.DELIVERED;
        pageRouter.moveToOfflineMode(OfflineModeTypes.DELIVERED);
      }
      appContext.store();
    } catch (error) {
      showErrorMessage(error);
    }
  }

  /**
   * ユーザーIDからユーザーのロールを判定する。
   * @param {string} inputId
   * @returns {CORE_DELIVERY_ROLE | DRIVER_ROLE} ユーザーのロール
   */
  function judgeUserRole(inputId) {
    if (inputId.match(/^[0]\d{3}\/.+/)) {
      // "0"から始まるIDの場合、幹線輸送担当ユーザーと判断
      return CORE_DELIVERY_ROLE;
    } else if (inputId.match(/^[1]\d{3}\/.+/)) {
      // "1"から始まるIDの場合、宅配ドライバーユーザーと判断
      return DRIVER_ROLE;
    } else {
      // それ以外のIDの場合、エラーメッセージ表示
      throw new HandledError($_("errors.loginFailed"));
    }
  }

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      errorMessage = error.message;
    } else {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        errorMessage = $_("errors.loginFailed");
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 400
      ) {
        errorMessage = $_("errors.loginFailedLimitOrver");
      } else {
        logger.error(
          "[ReLogin] 再ログインでエラーが発生しました",
          {
            username: userId,
          },
          error,
        );
        errorMessage = $_("errors.loginDefaultMessage");
      }
    }
  }
</script>

{#if !showsLogoutFlow}
  <p>
    ログインの期限が切れています。<br />
    お手数ですが、再度ログインしてください。
  </p>

  <div class="inputField">
    <Textfield
      type="text"
      label="ユーザーID"
      variant="outlined"
      style="margin-top: 20px;"
      required
      input$id="username"
      input$name="username"
      input$autocomplete="username"
      bind:value={userId}
      on:keydown={onEnterKeyDownHandler}
    />
  </div>
  <div class="inputField passwordField">
    <Textfield
      type={pwType}
      label="パスワード"
      variant="outlined"
      style="margin-top: 15px;"
      required
      input$id="current-password"
      input$name="current-password"
      input$autocomplete="current-password"
      bind:value={pw}
      on:keydown={onEnterKeyDownHandler}
    >
      <IconButton
        slot="trailingIcon"
        class="material-icons md-dark"
        tabindex={-1}
        on:click={() => {
          pwType = pwType == "text" ? "password" : "text";
        }}
      >
        {pwType == "text" ? "visibility_off" : "visibility"}
      </IconButton>
    </Textfield>
  </div>

  <div class="passwordReset">
    <Button
      color="secondary"
      ripple={false}
      on:click={() => {
        showsPasswordResetPage = true;
      }}
    >
      <Label>パスワードをお忘れの方はこちら</Label>
    </Button>
  </div>

  {#if errorMessage}
    <p class="errorMessage">
      <!-- ja.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
      {@html errorMessage}
    </p>
  {/if}
{:else}
  <p>以前のユーザーと異なるログイン情報が入力されました。</p>
  <p>一度ログアウトのうえ、再度ログイン画面からログインしてください。</p>
{/if}

<div class="command">
  {#if !showsLogoutFlow}
    <Button
      type="submit"
      variant="unelevated"
      style="width: 150px; height: 50px;"
      touch
      bind:disabled={loginButtonDisabled}
      on:click={login}
      >ログイン
    </Button>
  {:else}
    <Button
      type="submit"
      color="secondary"
      variant="unelevated"
      style="width: 150px; height: 50px;"
      touch
      on:click={logout}
    >
      ログアウト
    </Button>
    <Button
      type="submit"
      variant="unelevated"
      style="width: 150px; height: 50px; background-color: #909090;"
      touch
      on:click={() => {
        showsLogoutFlow = false;
      }}
    >
      戻る
    </Button>
  {/if}
</div>

<!-- ヘルプ表示 -->
{#if showsOfflineModeHelp}
  <HelpBase
    helpContents={HelpOfflineMode}
    clickConfirm={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
      switchOfflineMode();
    }}
    clickCancel={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
    }}
  />
{/if}

<style lang="scss">
  .passwordReset {
    :global(button) {
      font-size: 14px;
      font-weight: normal;
      text-decoration: underline;
    }
  }
</style>
