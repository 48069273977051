<script>
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { ConfirmDialogTypes } from "~/libs/constants";
  import { displayReLoginDialog } from "~/libs/stores";
  import PasswordReset from "~/pages/ReLogin/PasswordReset.svelte";
  import ReLogin from "~/pages/ReLogin/ReLogin.svelte";

  /**
   * ダイアログコンポーネントのインスタンス
   * @type {ConfirmDialog}
   */
  let dialog;

  /** ユーザーID */
  let userId = "";
  /** パスワード*/
  let pw = "";

  /** パスワードリセットページを表示するか否か */
  let showsPasswordResetPage = false;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialog.openDialog();
  }

  function closeDialog() {
    displayReLoginDialog.set(false);
    dialog.closeDialog();
  }
</script>

<div class="reLoginDialog">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.NONE}
    mandatory={true}
  >
    <svelte:fragment slot="title">
      {#if showsPasswordResetPage}
        パスワードのリセット
      {:else}
        再ログイン
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="content">
      {#if showsPasswordResetPage}
        <PasswordReset bind:userId bind:pw bind:showsPasswordResetPage />
      {:else}
        <ReLogin
          bind:userId
          bind:pw
          bind:showsPasswordResetPage
          {closeDialog}
        />
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .reLoginDialog {
    z-index: 9999;

    :global(.pageOverview) {
      margin-top: 20px;
      line-height: 20px;
    }

    :global(.inputField) {
      :global(.mdc-text-field) {
        width: 280px;
        background-color: #fff;
      }
    }

    :global(.passwordField) {
      :global(.mdc-icon-button) {
        margin: auto 0;
      }
    }

    :global(.errorMessage) {
      margin-top: 8px;
      color: rgb(228, 4, 4);
      font-size: 14px;
      font-weight: 600;
    }

    :global(.passwordStrengthChecker) {
      margin-top: 5px;
    }

    :global(.command) {
      margin-top: 30px;
      text-align: center;
    }
  }
</style>
