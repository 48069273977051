<script>
  import Button from "@smui/button";
  import { createEventDispatcher } from "svelte";

  import addressUtils from "~/libs/addressUtils";
  import { AddressTypeForMap, GOOGLE_MAP_BASE_URL } from "~/libs/constants";
  import { disposeIframe } from "~/libs/utils";

  /** 配達リストの荷物 @type {import("~/libs/commonTypes").DeliveryPackage} */
  export let listItem;

  /**
   * Svelte Event Dispatch Function
   * @type {(type: "updateInputAddress") => void}
   */
  const dispatch = createEventDispatcher();

  /** 住所入力ダイアログの住所入力値 */
  let inputAddress = listItem.enteredAddress
    ? listItem.enteredAddress
    : {
        [AddressTypeForMap.REGISTERED]: addressUtils.joinWithSpace(
          listItem.receiverAddress1,
          listItem.receiverAddress2,
        ),
        [AddressTypeForMap.INPUTTED]: listItem.enteredAddress,
        [AddressTypeForMap.CORRECTED]: listItem.correctedReceiverAddress,
      }[listItem.addressForMap];

  /** Googleマップのiframe URL（住所入力ダイアログ用） @type {string} */
  let googleMapUrl;
  $: {
    addressUtils
      .normalizeAddressForGoogleMap(inputAddress, listItem.receiverPostcode)
      .then((address) => {
        googleMapUrl = GOOGLE_MAP_BASE_URL.replace(
          "<address>",
          encodeURIComponent(address),
        );
      });
  }

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpend = false;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialogOpend = true;
  }
</script>

{#if dialogOpend}
  <div class="container">
    <div class="modal">
      <div class="modalTitle">
        <span class="material-icons md-dark md-24">edit</span>
        <span class="text">住所の補正</span>
      </div>
      <p class="modalP">
        地図に表示される住所を手入力で補正します。<br />
        登録された住所が変更されることはありません。
      </p>
      <div>
        <textarea
          name="editAddress"
          style="width: calc(100% - 2px); height: 40px; padding: 0; font-size: 16px;"
          bind:value={inputAddress}
        ></textarea>
        <iframe
          title="googlemap"
          src={googleMapUrl}
          width="100%"
          height="250"
          frameborder="0"
          style="border:0;"
          referrerpolicy="no-referrer"
          use:disposeIframe
        />
      </div>
      <div class="modalButtonArea">
        <Button
          style="color: #fff; background-color: #018786aa; width: 100px;"
          on:click={() => {
            dialogOpend = false;
          }}>キャンセル</Button
        >
        <Button
          style="color: #fff; background-color: {inputAddress.length === 0
            ? '#aaa'
            : '#018786'}; width: 100px;"
          on:click={() => {
            listItem.enteredAddress = inputAddress;
            listItem.addressForMap = AddressTypeForMap.INPUTTED;
            dispatch("updateInputAddress");
            listItem = listItem;
            dialogOpend = false;
          }}
          disabled={inputAddress.length === 0}>更新</Button
        >
      </div>
      <button
        class="modalCloseButton"
        on:click={() => {
          dialogOpend = false;
        }}>×</button
      >
    </div>
  </div>
  <div class="modalBackground" />
{/if}

<style lang="scss">
  .container {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--z-index-modal);
  }

  .modal {
    position: relative;
    width: 330px;
    margin: 0 auto;
    padding: 20px 10px;
    background-color: #fff;
    box-shadow: 0 1px 2px #ccc;
  }

  .modalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;

    .text {
      font-size: 20px;
    }
  }

  .modalP {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
  }

  .modalButtonArea {
    margin: 12px auto;
    text-align: center;
  }

  .modalCloseButton {
    position: fixed;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #fff;
    background-color: #999;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0;
    margin: 0;
  }

  .modalBackground {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: var(--z-index-sticky-area);
  }
</style>
