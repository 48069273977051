<script>
  import Button, { Label } from "@smui/button";
  import { createEventDispatcher, getContext, onDestroy, tick } from "svelte";

  import FinishWorkDialog from "~/components/FinishWorkDialog.svelte";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    QrHomeTypes,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import pageRouter from "~/libs/pageRouter";
  import { lastOperationType } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("~/libs/constants").LastOperationTypes} */
  let messageType;

  const lastOperationTypeUnsubscriber = lastOperationType.subscribe(
    async (value) => {
      if (value !== undefined) {
        await tick();
        messageType = value;
        lastOperationType.set(undefined);
        finishWorkDialog.openDialog();
      }
    },
  );

  /**
   * ダイアログコンポーネント（業務終了登録用）のインスタンス
   * @type {FinishWorkDialog}
   */
  let finishWorkDialog;

  /**
   * Svelte Event Dispatch Function
   * @type {(type: "reload") => void}
   */
  let dispatch = createEventDispatcher();

  onDestroy(() => {
    lastOperationTypeUnsubscriber();
  });
</script>

<div class="emptyListContent">
  <span class="material-icons icon">playlist_add_check</span>
  <p class="emptyText">配達が必要な荷物はありません</p>
  {#if !appContext.isReadOnlyMode}
    <button
      class="moveButton"
      on:click={() => {
        pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
      }}
    >
      配送センターから荷物を持ち出す
    </button>
    {#if deliveryListUtils.canFinishWork(userContext.deliveryList, userContext.syncFailureList)}
      <Button
        color="secondary"
        ripple={false}
        on:click={finishWorkDialog.openDialog}
      >
        <Label>宅配業務を終了する</Label>
      </Button>
    {/if}
  {:else}
    <button
      class="moveButton"
      on:click={() => {
        dispatch("reload");
      }}
    >
      配達リストの再読み込み
    </button>
    <Button
      color="secondary"
      ripple={false}
      on:click={pageRouter.moveToBeginWork}
    >
      <Label>業務開始画面に戻る</Label>
    </Button>
  {/if}
</div>

<!-- 業務終了登録ダイアログ -->
<FinishWorkDialog bind:this={finishWorkDialog} bind:messageType />

<style lang="scss">
  .emptyListContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 200px;
      color: #d9d9d9;
    }
    .emptyText {
      margin: -10px 0 45px;
    }
    .moveButton {
      margin-bottom: 30px;
      padding: 14px 30px;
      color: #fff;
      background-color: #018786;
      border: none;
      border-radius: 10px;
      font-size: 17px;
    }
  }
</style>
