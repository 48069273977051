<script>
  import Button, { Label } from "@smui/button";
  import { getContext, onDestroy, tick } from "svelte";

  import FinishWorkDialog from "~/components/FinishWorkDialog.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import { lastOperationType } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("~/libs/constants").LastOperationTypes} */
  let messageType;

  const lastOperationTypeUnsubscriber = lastOperationType.subscribe(
    async (value) => {
      if (value !== undefined) {
        await tick();
        messageType = value;
        lastOperationType.set(undefined);
        finishWorkDialog.openDialog();
      }
    },
  );

  /**
   * ダイアログコンポーネント（業務終了登録用）のインスタンス
   * @type {FinishWorkDialog}
   */
  let finishWorkDialog;

  onDestroy(() => {
    lastOperationTypeUnsubscriber();
  });
</script>

<div class="emptyTabContent">
  <span class="material-icons icon">playlist_add_check</span>
  <p class="emptyText">
    <slot name="packageType" />はありません
  </p>
  {#if deliveryListUtils.canFinishWork(userContext.deliveryList, userContext.syncFailureList)}
    <!-- IF 未配達・配達不可の荷物数が0の場合 -->
    <Button
      color="secondary"
      ripple={false}
      on:click={finishWorkDialog.openDialog}
    >
      <Label>宅配業務を終了する</Label>
    </Button>
  {/if}
</div>

<!-- 業務終了登録ダイアログ -->
<FinishWorkDialog bind:this={finishWorkDialog} bind:messageType />

<style lang="scss">
  .emptyTabContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 200px;
      color: #d9d9d9;
    }
    .emptyText {
      margin: -10px 0 30px;
    }

    :global(.mdc-button) {
      background-color: #018786;
      color: white;
      border: none;
      display: inline-block;
      font-size: 16px;
      height: 50px;
      padding: 14px 30px;
    }
  }
</style>
