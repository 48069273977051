<script>
  import Button, { Label } from "@smui/button";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    ConfirmDialogTypes,
    DRIVER_ROLE,
    DriverType,
    LastOperationTypes,
    QrHomeTypes,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { switchRole } from "~/libs/switchRole";
  import { toast } from "~/libs/toast";
  import workManage from "~/libs/workManage";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  export let messageType = undefined;

  /** 宅配業務中の時、幹線輸送業務側で輸送中荷物が残っている場合にtrue */
  let hasInTransitPackage =
    userContext.hasDriverRole() &&
    userContext.getNumberOfInTransitPackages() > 0
      ? true
      : false;

  /** 幹線輸送業務中の時、宅配業務側で持出し中荷物が残っている場合にtrue */
  let hasOutForDeliveryPackage =
    userContext.hasContractDriverRole() &&
    !deliveryListUtils.canFinishWork(
      userContext.deliveryList,
      userContext.syncFailureList,
    );

  /** @type {ConfirmDialog} 確認ダイアログコンポーネントのインスタンス */
  let dialog;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialog.openDialog();
  }

  /**
   * 業務を終了して業務開始画面に遷移する
   */
  async function finishWorkAndMoveToBeginWork() {
    // ログインユーザーの業務終了を記録
    try {
      await workManage.finish(
        userContext,
        userContext.hasDriverRole()
          ? DriverType.DRIVER
          : DriverType.CORE_DELIVERY,
      );

      pageRouter.moveToBeginWork();
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      }
    }
  }

  /**
   * 業務を続ける
   */
  function continueWork() {
    messageType = undefined;
    dialog.closeDialog();
  }

  /**
   * ロールを切り替え、切替え前業務の終了登録を行う
   * @param {DRIVER_ROLE | CORE_DELIVERY_ROLE} switchTargetRole 切替え先のロール
   */
  async function switchRoleAndFinishWork(switchTargetRole) {
    try {
      await switchRole(userContext, switchTargetRole);

      // 業務終了登録
      await workManage.finish(
        userContext,
        switchTargetRole === DRIVER_ROLE
          ? DriverType.CORE_DELIVERY
          : DriverType.DRIVER,
      );
      userContext.store();

      if (switchTargetRole === DRIVER_ROLE) {
        pageRouter.moveToList();
      } else {
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
      }
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        // backendApi.jsで401エラーが起きた際に再ログインダイアログを表示しているため、ここでは何もせずに処理を終了する
        return;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        // サーバーエラー応答等が発生した場合
        logger.error(
          "[FinishWorkDialog] ロール切替えでエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }
  }
</script>

<div class="syncFailureListDialog">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.NONE}
    mandatory={true}
  >
    <svelte:fragment slot="title">
      {#if hasInTransitPackage || hasOutForDeliveryPackage}
        業務切替え確認
      {:else}
        業務終了確認
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="content">
      {#if messageType === LastOperationTypes.ALL_DELIVERED}
        <span>全ての荷物の配達が完了しました。</span>
      {:else if messageType === LastOperationTypes.ALL_TAKEBACK}
        <span>全ての荷物の持ち戻りが完了しました。</span>
      {:else if messageType === LastOperationTypes.ALL_UNLOAD}
        <span>全ての荷物の荷下ろしが完了しました。</span>
      {/if}
      {#if hasInTransitPackage}
        <span>幹線輸送業務で輸送中の荷物が残っています。</span>
      {:else if hasOutForDeliveryPackage}
        <span>宅配業務で持出し中の荷物が残っています。</span>
      {:else}
        <span>業務の終了を管理者に申告します。</span>
      {/if}
      <div class="buttonArea">
        {#if hasInTransitPackage}
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={loadingProgress.wrapAsync(async () => {
              await switchRoleAndFinishWork(CORE_DELIVERY_ROLE);
            })}
            style="width: 100%; height: 50px;"
          >
            <Label>幹線輸送業務に切替え</Label>
          </Button>
        {:else if hasOutForDeliveryPackage}
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={loadingProgress.wrapAsync(async () => {
              await switchRoleAndFinishWork(DRIVER_ROLE);
            })}
            style="width: 100%; height: 50px;"
          >
            <Label>宅配業務に切替え</Label>
          </Button>
        {:else}
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={loadingProgress.wrapAsync(finishWorkAndMoveToBeginWork)}
            style="width: 100%; height: 50px;"
          >
            <Label>業務を終了</Label>
          </Button>
        {/if}

        <Button
          class="registerButton"
          color="secondary"
          variant="outlined"
          on:click={continueWork}
          style="width: 100%; margin-top: 15px;"
        >
          {#if hasInTransitPackage}
            <Label>切替えず宅配業務を継続する</Label>
          {:else if hasOutForDeliveryPackage}
            <Label>切替えず幹線輸送業務を継続する</Label>
          {:else if userContext.hasDriverRole()}
            <Label>終了せず追加で荷物を持ち出す</Label>
          {:else if messageType === LastOperationTypes.ALL_UNLOAD || messageType === LastOperationTypes.RETURN_TO_EC}
            <Label>終了せず幹線輸送業務を継続する</Label>
          {:else}
            <Label>終了せず荷受けを行う</Label>
          {/if}
        </Button>
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .syncFailureListDialog {
    :global(.mdc-dialog__title) {
      text-align: center;
    }

    .buttonArea {
      display: column;
      text-align: center;
      justify-content: center;
      gap: 2px;
      margin-top: 10px;
    }
  }
</style>
