<script>
  import Button, { Label } from "@smui/button";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import {
    AVAILABLE_DROP_OFF_LOCATIONS,
    CONTEXT_KEY_APP,
    ConfirmDialogTypes,
    DropOffLocation,
  } from "~/libs/constants";
  import { deliveryTarget } from "~/libs/stores";
  import LockerInformation from "~/pages/Update/LockerInformation.svelte";
  import PriorAgreementForDropPlaceDialog from "~/pages/Update/PriorAgreementForDropPlaceDialog.svelte";

  /** @type {number} 荷受け人が希望した受け渡し方法 */
  export let desiredPackageDropPlace;
  /** @type {number} 宅配ドライバーが選択した受け渡し方法 */
  export let actualPackageDropPlace;
  /** @type {Array<string>} 宅配ボックスのボックス番号（コンポーネント間連携用） */
  export let lockerNumberList = [];
  /** @type {Array<string>} 宅配ボックスの暗証番号（コンポーネント間連携用） */
  export let lockerPinList = [];
  /** @type {string} 荷受け人の電話番号（コンポーネント間連携用） */
  export let receiverTel;
  /** @type {string} 置き配事前確認の連絡方法（コンポーネント間連携用） */
  export let contactMethod;
  /** @type {string} 置き配事前確認で指定された置き配場所（コンポーネント間連携用） */
  export let designatedDropPlace;
  /** @type {string} 置き配事前確認で指示内容を登録した日時（コンポーネント間連携用） */
  export let agreedAt;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {number} 宅配ボックス情報の個数 */
  let numOfLockerInfo = 1;

  /** @type {number} 荷受人が希望した受け渡し方法 */
  const packageDropPlace = $deliveryTarget.packageDropPlace;

  /** @type {boolean} ヘルプダイアログの開閉フラグ */
  let helpPageOpen = false;

  /** @type {PriorAgreementForDropPlaceDialog} 置き配の事前合意についてのダイアログ */
  let priorAgreementForDropPlaceDialog;

  /** @type {ConfirmDialog} 宅配ボックス使用時の注意事項の確認ダイアログコンポーネントのインスタンス */
  let cautionOfLockerDialog;

  const selectableList = (() => {
    let selectableList = [];
    for (const availableDropOffLocation of AVAILABLE_DROP_OFF_LOCATIONS) {
      let selectable = false;
      let receiverSelectable = false;

      if (availableDropOffLocation !== DropOffLocation.OTHER) {
        if (
          $deliveryTarget.customer?.availableDeliveryMethod?.includes(
            availableDropOffLocation,
          )
        ) {
          selectable = true;
        }

        if (
          $deliveryTarget.customer?.receiverSelectableDeliveryMethod?.includes(
            availableDropOffLocation,
          )
        ) {
          receiverSelectable = true;
        }
      } else {
        selectable = true;
        receiverSelectable = true;
      }

      selectableList.push({
        deliveryMethod: availableDropOffLocation,
        selectable: selectable,
        receiverSelectable: receiverSelectable,
      });
    }
    return selectableList;
  })();

  (() => {
    actualPackageDropPlace = packageDropPlace;
  })();

  function toggleHelpPage() {
    helpPageOpen = !helpPageOpen;
  }

  /**
   * 宅配ボックス情報を追加する（Maxは荷物の個口数、かつ10個）
   */
  function addLockerInfo() {
    if (
      (numOfLockerInfo < $deliveryTarget.numberOfPackages ||
        appContext.offlineMode) &&
      numOfLockerInfo < 10
    ) {
      lockerNumberList.push("");
      lockerPinList.push("");
      lockerNumberList = lockerNumberList;
      lockerPinList = lockerPinList;
      numOfLockerInfo++;
    }
  }
  /**
   * 宅配ボックス情報を削除する
   * @param {object} event
   */
  function deleteLockerInfo(event) {
    lockerNumberList.splice(event.detail, 1);
    lockerPinList.splice(event.detail, 1);
    lockerNumberList = lockerNumberList;
    lockerPinList = lockerPinList;
    numOfLockerInfo--;
  }

  function selectOther() {
    actualPackageDropPlace = DropOffLocation.OTHER;
  }
</script>

<!-- 配達場所 -->
<div class="changeArea">
  <div class="changeTitle">
    <p>
      受け渡し方法の選択
      <span class="required">必須</span>
    </p>
    <button class="helpBtn" on:click={toggleHelpPage}>
      <div style="width: 30px;">
        <span class="material-icons md-dark md-24">info_outline</span>
      </div>
    </button>
  </div>

  <div class="placeButtons">
    {#each selectableList as selectableMethod}
      {#if selectableMethod.selectable || selectableMethod.deliveryMethod === desiredPackageDropPlace}
        <button
          class="placeButton"
          class:desired={selectableMethod.deliveryMethod === packageDropPlace}
          class:exCurrent={selectableMethod.deliveryMethod ===
            actualPackageDropPlace}
          on:click={() => {
            if (
              agreedAt ||
              selectableMethod.deliveryMethod !== DropOffLocation.OTHER
            ) {
              // 事前合意がある場合、またはない場合でも「その場置き配」以外の場合は、受け渡し方法を変更する

              if (
                selectableMethod.deliveryMethod === DropOffLocation.LOCKER &&
                desiredPackageDropPlace !== DropOffLocation.LOCKER &&
                actualPackageDropPlace !== selectableMethod.deliveryMethod
              ) {
                // TEMUに対する臨時対応として、荷受け人が希望していない場合に宅配ボックスが選択された場合はインターホンで不在確認が必要である旨を示す
                cautionOfLockerDialog.openDialog();
              } else {
                actualPackageDropPlace =
                  actualPackageDropPlace === selectableMethod.deliveryMethod
                    ? null
                    : selectableMethod.deliveryMethod;
              }
            } else {
              priorAgreementForDropPlaceDialog.openDialog();
            }
          }}
          >{$_(
            `classes.packageDropPlace.${selectableMethod.deliveryMethod}`,
          )}</button
        >
      {/if}
    {/each}
  </div>
  {#if Number.isInteger(packageDropPlace) && Number.isInteger(actualPackageDropPlace) && actualPackageDropPlace !== packageDropPlace}
    <div class="placeWarning">
      <span class="material-icons">warning</span>
      <span>ご希望と異なる受け渡し方法が選択されています</span>
    </div>
  {/if}

  {#if contactMethod && designatedDropPlace}
    <div class="instructionsAreaForDropPlace">
      <div class="instructionsAreaHeader">
        <p class="instructionsAreaTitle">配達場所に関するご指示</p>
        <button
          class="editButton"
          on:click={() => {
            priorAgreementForDropPlaceDialog.openDialog();
          }}
        >
          <span class="material-icons">edit</span><span>編集する</span>
        </button>
      </div>
      <div class="instructionsAreaContents">
        {#if Number.isInteger(Number(designatedDropPlace))}{$_(
            `classes.packageDropPlace.${designatedDropPlace}`,
          )}{:else}{designatedDropPlace}{/if}への置き配（{#if Number.isInteger(Number(contactMethod))}{$_(
            `classes.contactMethod.${contactMethod}`,
          )}{:else}{contactMethod}{/if}で確認済み）
      </div>
    </div>
  {/if}

  <div
    class="lockerAreaWrapper"
    style:display={actualPackageDropPlace === DropOffLocation.LOCKER
      ? "block"
      : "none"}
  >
    {#each [...Array(numOfLockerInfo).keys()] as i}
      <LockerInformation
        bind:indexOfLockerInfo={i}
        bind:lockerNumberList
        bind:lockerPinList
        on:deleteLockerInfo={deleteLockerInfo}
      />
    {/each}

    {#if (($deliveryTarget.numberOfPackages > 1 && numOfLockerInfo < $deliveryTarget.numberOfPackages) || appContext.offlineMode) && numOfLockerInfo < 10}
      <div class="addLockerInfoButtonArea">
        <button class="addLockerInfoButton" on:click={addLockerInfo}>
          {numOfLockerInfo + 1}個目の宅配ボックス情報を登録する
        </button>
      </div>
    {/if}

    <!-- オフラインモード用注記 -->
    {#if appContext.offlineMode && (!lockerNumberList.every((lockerNumber) => lockerNumber === null) || lockerPinList.every((lockerPin) => lockerPin === null))}
      <p class="notion">
        ※現在オフラインモードのため、宅配ボックス情報を記入した紙を忘れずに郵便受けに投函してください。
      </p>
    {/if}
  </div>

  <!-- ヘルプダイアログ -->
  <div class="dropPlace" style="display: {helpPageOpen ? 'block' : 'none'}">
    <div class="dropPlaceContentWrap">
      <button class="dropPlaceCloseLabel" on:click={toggleHelpPage}>×</button>
      <div class="dropPlaceContent">
        <div class="dropPlaceArea">
          <div class="dropPlaceTitle">
            <div class="icons" style="width: 30px; margin-right: 5px">
              <span class="material-icons md-dark md-24">help_outline</span>
            </div>
            <h3>受け渡し方法について</h3>
          </div>
          <div class="dropPlaceNotion">
            <span
              >EC事業者との契約上許可されていない受け渡し方法は選択できません。
              <p style="height: 8px;"></p>
              「要望時のみ可」と表示されている受け渡し方法は、荷受人との電話やインターホンでの会話によって指示を受けた場合のみ選択してください。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="dropPlaceBackground" />
  </div>
</div>

<div class="cautionOfLockerDialog">
  <ConfirmDialog
    bind:this={cautionOfLockerDialog}
    type={ConfirmDialogTypes.CLOSE}
    mandatory={true}
  >
    <svelte:fragment slot="title">宅配ボックス使用時の注意</svelte:fragment>
    <svelte:fragment slot="content">
      <span>インターホンを押し、不在を確認してから使用してください。</span>

      <Button
        class="registerButton"
        color="secondary"
        variant="unelevated"
        on:click={() => {
          actualPackageDropPlace = DropOffLocation.LOCKER;
          cautionOfLockerDialog.closeDialog();
        }}
        style="width: 100%; margin-top: 15px;"
      >
        <Label>不在を確認した</Label>
      </Button>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<PriorAgreementForDropPlaceDialog
  {selectableList}
  {desiredPackageDropPlace}
  bind:this={priorAgreementForDropPlaceDialog}
  {receiverTel}
  bind:contactMethod
  bind:designatedDropPlace
  bind:agreedAt
  {selectOther}
/>

<style lang="scss">
  /* ------------------
    受け渡し方法の選択
  --------------------- */
  .changeArea {
    display: block;
    background-color: #fff;
    border-radius: 10px;

    .changeTitle {
      position: relative;
      margin: 0 0 10px 30px;
      display: flex;
      font-weight: bold;

      .required {
        display: inline-block;
        padding: 2px 4px;
        margin-left: 4px;
        color: #c80000;
        background-color: #c8000010;
      }
    }

    .helpBtn {
      position: absolute;
      top: -7px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }

  .placeButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .placeButton {
      width: 110px;
      height: 45px;
      padding: 0;
      background-color: #fff;
      border: 1px solid #018786;
      border-radius: 10px;
      box-shadow: 0 2px #ccc;
      font-size: 13px;
      color: #333;
      line-height: 1.2;

      &.desired {
        color: #2400d6;
      }

      &.exCurrent {
        background-color: #2400d610;
      }
    }
  }

  .placeWarning {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    color: var(--mdc-theme-error);

    span {
      font-size: 13px;
    }
  }

  .instructionsAreaForDropPlace {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    padding: 15px 10px;
    text-align: left;

    .instructionsAreaHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .instructionsAreaTitle {
        margin-left: 4px;
        font-size: 16px;
        font-weight: bold;
      }
      .editButton {
        padding: 1px 4px;
        background-color: #fff;
        border: 1px solid #018786;
        border-radius: 4px;

        span {
          font-size: 10px;
          color: #018786;

          &.material-icons {
            font-size: 14px;
            vertical-align: middle;
          }
        }
      }
    }
    .instructionsAreaContents {
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 15px;
      line-height: 1.3;
      padding: 10px;
    }
  }

  .addLockerInfoButtonArea {
    text-align: right;

    .addLockerInfoButton {
      background-color: transparent;
      border: none;
      color: #018786;
      margin: 0 10px;
    }
  }

  .notion {
    margin-top: 10px;
    font-size: 13px;
    line-height: 15px;
    color: #ff0000;
  }

  /* ------------------
    モーダルウィンドウ
   -------------------- */

  /* 写真撮影のポイント */
  .dropPlace {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
    display: none;

    .dropPlaceContentWrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 350px;
      background-color: #fefefe;
      z-index: var(--z-index-sticky-area);
      border-radius: 5px;
    }
    .dropPlaceCloseLabel {
      background-color: #777;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      width: 36px;
      height: 36px;
      line-height: 1.5;
      text-align: center;
      display: table-cell;
      position: fixed;
      top: -15px;
      right: -2%;
      z-index: var(--z-index-modal);
      font-size: 1.4em;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
    .dropPlaceContent {
      max-height: 60vh;
      overflow-y: auto;
      padding: 30px;
    }
    .dropPlaceArea {
      position: relative;
      margin: 0 auto;
      padding: 15px 0;
      background-color: #fff;
      border-radius: 10px;
    }
    .dropPlaceTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .dropPlaceTitle h3 {
      font-size: 18px;
    }
    .dropPlaceNotion {
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      padding-left: 10px;
      list-style-type: circle;
    }
    .dropPlaceBackground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: calc(var(--z-index-sticky-area) - 1);
    }
  }

  .cautionOfLockerDialog {
    text-align: left;
  }
</style>
